import React, { useState,useRef } from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import {SIGNUP_URL,SIGNUP_STATIC_TOKEN, SIGNUP_USER_API,SIGNUP_RESET_PASSWORD_API} from '../../Config/AppConfiguration';
import { Button, Spinner } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import  ReCAPTCHA  from 'react-google-recaptcha';
import { ToastContainer, toast ,Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './ResetPassword.css';

var FormFields = {
    Email: {
      label: "emailInput",
      regex:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    }
  };

const ResetPassword = () => {
  const [emailId, setEmailId] = useState('');
  const[isFormFieldsValid,setIsFormFieldsValid] = useState('false');
  const [captchaStatus, setCaptchaStatus] = useState({ success: false, message: '' });
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [modalShow, setModalShow] = React.useState(false);
  const [isCreateButtonActive, setIsCreateButtonActive] = useState(false);
  const [passwordResetConfirmationMsg, setPasswordResetConfirmationMsg] = useState("");

  const navigate = useNavigate();
  const navigateHome = () => {
    navigate('/');
  };
  let captchaReference = useRef();
  console.log("emailId ---"+emailId);
  const resetRecaptcha = () => {
    captchaReference.reset();
  };
    function onChange(value) {
        console.log("Captcha value:", value);
        setRecaptchaValue(value);
        if(emailId!==null)
       {setIsFormFieldsValid(true)}
      }
      console.log("isformfield valid" +isFormFieldsValid);
      console.log("recaptcha response===>"+recaptchaValue);
      let userDetailsObject = {
        email: '',
       };
      function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
            backdrop='static' 
            keyboard="False"
            dialogClassName='my-modal'
          >
            <Modal.Header className='modal-header'>
              <Modal.Title id="contained-modal-title-vcenter">
              Confirmation Of Account Creation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
              <p>
              {"Your password has been reset successfully, " +passwordResetConfirmationMsg}
              </p>
            </Modal.Body>
            <Modal.Footer>
            <button className="ui button comm-button-style primary" onClick={navigateHome}>OK</button>
            </Modal.Footer>
          </Modal>
        );
      }
      const handleSubmit = async (e) => {
        e.preventDefault();
        setIsCreateButtonActive(true);
      
        userDetailsObject.email=emailId;
  
        try {
            const response=await  axios.post(SIGNUP_RESET_PASSWORD_API,userDetailsObject,{ headers: {'Content-Type': 'application/json','g-recaptcha-response':recaptchaValue,Authorization: 'Bearer ' +SIGNUP_STATIC_TOKEN} })
           if(response){
            setPasswordResetConfirmationMsg(response.data.message);
            setIsCreateButtonActive(false); 
            setModalShow(true);
            resetRecaptcha();
        }
      } catch (error) {
            console.log(error);
        }
      }
      
      return (
            <div id="joinUsContainer" className="container-fluid" style={{height:'100%'}}>
                <div className="row" style={{minHeight: '110px', backgroundColor: 'white'}} >
<div className="empty-cont-header" style={{minHeight: '10px'}}>
  <h1>
RESET PASSWORD
</h1>
</div>

</div>

    
<div className="main-content">
              <section className="join-us-wrap">
                <ul className="signup-elements-wrap mt-2">
                  <li>
                    <div className="custom-input-wrapp">
                    <FloatingLabel
        controlId="emailInput"
        label="Email Id"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setEmailId(event.target.value)}
      >
        <Form.Control type="email" placeholder="name@example.com" />
        <span className="input-info-label">Eg: username@domain.com</span>
        <span className="required-field-op">(Required)</span>
        <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Email Id is invalid</span>
      </FloatingLabel>
                    </div>
                  </li>
                  <li className="custom-input-wrapp">
                  <div id="captcha_joinus_element">
                        
  <ReCAPTCHA
  sitekey={"6Ldl6MwZAAAAAD8KlVqTRNxW63kBJD43BTbVDvwE"} 
  onChange={onChange}
  ref={e=>captchaReference=e}
          />
  </div>
                  </li>
       
      <p>{captchaStatus.message}</p>
                  <li className="min-marg privacy-policy-txt" style={{display: 'none'}}>
                    <p>By signing up you are indicating that you have read &amp; agree to the 
                      <a href="../../SiteAssets/APIMarketplace/Gfx/Privacy-Notice-API-Market-Place.pdf" target="_blank">Privacy Policy</a>.</p>
                  </li>
                  
                  <li>
                    <div className="apim-pop-button-w comm-button-wrap">
                      <button className="ui button comm-button-style primary apim-pop-button-create" id="resetPassword"  onClick={handleSubmit} disabled={!isFormFieldsValid} > {isCreateButtonActive===true?"RESETTING PASSWORD..." :"REQUEST NEW PASSWORD"}</button>
                      {isCreateButtonActive===true?
                      <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        
          />:<></>}
          <button className="ui button comm-button-style secondary custom-loader" id="loadingJU" style={{display: 'none'}}>
                        <i className="icon-plus iconLoader" />
                        Joining..
                      </button>
                      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
                      <button className="ui button comm-button-style secondary apim-pop-button-cancel" id="cancelJU" formNoValidate onClick={navigateHome}>Cancel</button>
                    </div>
                  </li>
                </ul>
              </section>
              </div>
              <div className="toast-container"><ToastContainer limit={2}/></div>
            </div>
          );
    
}

export default ResetPassword;





