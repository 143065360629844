import "./ProfilePage.css";
import React, { useState, useEffect } from 'react';
import ChangeDisplayName from "./ChangeDisplayName";
import DeleteAccount from "./DeleteAccount";
import ChangePhoneNumber from "./ChangePhoneNumber";
import MyProfile from "./MyProfile";
import ChangePassword from "./ChangePassword";
import Notification from "./Notification";
import Cookies from 'universal-cookie';

const UserProfileMainComponent = () => {
  const cookieNameCurrentUser = "currentAPIMUser";
  
  const [user, setUser] = useState('');
  const [page, setPage] = useState('');


     useEffect(() => {
      const cookies = new Cookies();
      if(cookies!==null){
        var _user_attributes = cookies.get(cookieNameCurrentUser);
      if (_user_attributes) {
        _user_attributes = decodeURI(_user_attributes);
        const user_attributes = JSON.parse(_user_attributes);
        setUser(user_attributes);
      }
    }
      },[]);

  return (
    <div className="container-fluid mt-3 ps-3">
      <div className="container row">
        <div className="col-4 ">
          <div className="mp-user-info-w">
            <span className="user-aplha" id="userInitial">
            {user.displayName && user.displayName.slice(0, 1)}
            
            </span>
          </div>
        </div>
        <div className="col-8 ">
          <ul className="mp-user-info">
            <li id="userName">{user.displayName}</li>
            <li id="userEmailId">{user.email}</li>
            <li id="userPhoneNo">{user.mobileNumber}</li>
          </ul>
        </div>
      </div>
      <div className="container row">
        <div className="col-4">
          <div className="list-group" id="list-tab" role="tablist">
            <b
              className="list-group-item list-group-item-action item p-2"
            >
              My Profile
            </b>
            <a
              className="list-group-item list-group-item-action item p-2 active"
              id="list-profile-list"
              data-bs-toggle="list"
              href="#changePassword"
              role="tab"
              aria-controls="list-profile"
            >
              Change Password
            </a>
            <a
              className="list-group-item list-group-item-action item p-2"
              id="list-messages-list"
              data-bs-toggle="list"
              href="#changePhoneNumber"
              role="tab"
              aria-controls="list-messages"
            >
              Change Phone Number
            </a>
            <a
              className="list-group-item list-group-item-action item p-2"
              id="list-settings-list"
              data-bs-toggle="list"
              href="#changeDisplayName"
              role="tab"
              aria-controls="list-settings"
            >
              Change Display Name
            </a>
            <a
              className="list-group-item list-group-item-action item p-2"
              id="list-messages-list"
              data-bs-toggle="list"
              href="#notifications"
              role="tab"
              aria-controls="list-messages"
              onClick={()=>setPage('t5')}
            >
              Notifications
            </a>
            <a
              className="list-group-item list-group-item-action item p-2"
              id="list-settings-list"
              data-bs-toggle="list"
              href="#deleteMyAccount"
              role="tab"
              aria-controls="list-settings"
            >
              Delete My Account
            </a>
          </div>
        </div>
        <div className="col-8">
          <div className="tab-content" id="nav-tabContent">
            
            <div
              className="tab-pane fade show active"
              id="changePassword"
              role="tabpanel"
              aria-labelledby="list-profile-list"
            >
              <ChangePassword />
            </div>
            <div
              className="tab-pane fade"
              id="changePhoneNumber"
              role="tabpanel"
              aria-labelledby="list-messages-list"
            >
              <ChangePhoneNumber />
            </div>
            <div
              className="tab-pane fade"
              id="changeDisplayName"
              role="tabpanel"
              aria-labelledby="list-settings-list"
            >
              <ChangeDisplayName />
            </div>
            <div
              className="tab-pane fade"
              id="notifications"
              role="tabpanel"
              aria-labelledby="list-messages-list"
            >
               {page === 't5'?<Notification />:<></>}
            </div>
            <div
              className="tab-pane fade"
              id="deleteMyAccount"
              role="tabpanel"
              aria-labelledby="list-settings-list"
            >
              <DeleteAccount />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileMainComponent;
