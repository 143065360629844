import React, { useState, useEffect,useRef } from 'react';
import {useNavigate} from 'react-router-dom';
import {Popover} from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ProjectsService from '../../Services/ProjectsService';
import './ServiceRequest.css';
import { Outlet} from "react-router-dom";
import Metadata from '../../ServiceReqMetadata';
import CreateCompany from './CreateCompany';
import ManageCompany from './ManageCompany';
import ContactSales from './ContactSales';
import APIVersionException from './APIVersionException';
import ReportIssue from './ReportIssue';
import TechnicalAssistance from './TechnicalAssistance';
import OtherEnquiries from './OtherEnquiries';
import { useParams } from "react-router-dom";

const ServiceRequest = () => {

  const { company, confirmedRepId ,requestType,requestSubType} = useParams();
  //alert ("companyId, cRepId ,rqType,reqSubType"+company+confirmedRepId+requestType+requestSubType)
   const [requestTypeDisplayName, setRequestTypeDisplayName] = useState([]);
   const [selectedRequestTypeValue, setSelectedRequestTypeValue] = useState(requestType==="ManageCompany"?
   "Manage Company Records":''
  
  );
   const [srqMainPopoverMsg, setSrqMainPopoverMsg] = useState('');
  const cookieNameToken = "accessToken";
  const [rqTypeFromURL, setRqTypeFromURL] = useState('');

  
  console.log("all url params are---"+company+confirmedRepId+requestType+requestSubType)
  // if(requestType==="ManageCompany")
  // {
  //   setRqTypeFromURL("Manage Company Records");
  // }
 

  

  const handleSelectChange = (event) => {
    let selectedValue=event.target.value;
    setSelectedRequestTypeValue(selectedValue);
    var msg = Metadata.filter(record => record.requestType===selectedValue) 
    .map((e) => {return  e.whenToUse} );
    setSrqMainPopoverMsg(msg);
  };
  const handleClearSelection = () => {
    setSelectedRequestTypeValue("");
  };
 
  const popover = (data) =>(  
  
    <Popover id="popover-basic" style={{maxWidth: '700px',height:'90px',borderColor:'black',borderStyle:'solid'}}>    
      <Popover.Body className='row'>  
        <p  className='row'> {data}</p>
      </Popover.Body>  
    </Popover>  
  ); 
  const customstyles = {
    dropdownindicator: base => ({
      ...base,
      fill: "red" // custom colour
    })
  };

useEffect(() => {
  
  var requestType = Metadata.map((e) =>  e.displayName );
  setRequestTypeDisplayName(requestType);
  console.log("ServiceReqMetadata::------->>"+Metadata.map((e) =>  e.requestType ));
  console.log("displayName::------->>"+Metadata.map((e) =>  e.displayName ));

  if(company==="ManageCompany")
  {
    setSelectedRequestTypeValue("Manage Company Records");
  }
  
    
  },[])
//   const handleOtp = (OtpDataToProject) => {
//     setOtpDataFromModal(OtpDataToProject);
//   }
  
  
  const navigate = useNavigate();
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };
  
 

      
     

     
     
      async function handleSubmit() {

        try {
            const response = await ProjectsService.getStrongConfirmationCode("PUT",null,"changePassword",null,null,null)
        if (response && response.data.channel == "SMS") {
           
          }
       
            
        } catch (error) {
            console.log(error);
        }
       // setModalShow(true);  //enable here for modal testing
       
      }
 
        return (
          <div
            id="sericeRequestContainer"
            className="container-fluid"
            style={{ height: "100%" }}
          >
            <div
              className="row"
              style={{ minHeight: "110px", backgroundColor: "white" }}
            >
              <div className="empty-cont-header" style={{ minHeight: "10px" }}>
                <h1 style={{color:'#2d32aa'}}>SERVICE REQUEST</h1>
              </div>
            </div>

            <div className="main-content">
              <section>
                <div className="row api-prod-row api-prod-header">
                  <div className="api-prod-col col-xs-12">
                    <section className="serv-req-cre-main">
                      <ul className="serv-req" id="serviceRequestDD">
                        <li>
                          <h2>CREATE SERVICE REQUEST </h2>
                          
    <div className="drop-d-w">
      <div
        id="requestTypeDD"
        className="ui selection dropdown"
        tabIndex={1}
        style={{ position: 'relative' }}
      >
        <input
          type="hidden"
          name="selectSerReq"
          value={selectedRequestTypeValue || ""}
        />

        {selectedRequestTypeValue ? (
          <span 
            className="clear-icon-serviceRqst"
            onClick={handleClearSelection}
          >
            &times;
          </span>
        ) : (
          <i className="dropdown icon" />
        )}

        <div>
          <Form.Select
            value={selectedRequestTypeValue}
            onChange={handleSelectChange}
            styles={customstyles}
            style={{ width: "100%" }}
            className={selectedRequestTypeValue ? 'dropdown-with-close' : 'dropdown-default'}
          >
            <option
              className="textdefault"
              value="Select Service Request"
            >
              Select Service Request
            </option>

            {requestTypeDisplayName.map((option, index) => (
              <option 
                key={index} 
                value={
                  Metadata.filter(record => record.displayName === option)
                  .map(e => e.requestType)
                }
              >
                {option}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>

      <div className="col-sm-1">
        {srqMainPopoverMsg !== "" && (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={popover(srqMainPopoverMsg)}
          >
            <a className="info-icon-w ms-2" target="_blank" rel="noopener noreferrer">
              <span className="bi bi-info-circle mt-2" />
            </a>
          </OverlayTrigger>
        )}
      </div>
    </div>
                        </li>
                      </ul>
                      <ul className="after-sel-sec serviceRequestFormBuilder">
                        {/* to render different components on selection of select options */}
                        <li>
                          {selectedRequestTypeValue === "CreateCompany" && (
                            <CreateCompany />
                          )}
                            {selectedRequestTypeValue ===
                            "ManageCompany" && (
                              <ManageCompany rqValue={selectedRequestTypeValue} company={company} requestSubType={requestSubType} confirmedRepId={confirmedRepId} />
                          )}
                          {selectedRequestTypeValue ===
                            "APIVersionException" && (
                            <APIVersionException />
                          )}
                          {selectedRequestTypeValue ===
                            "TechnicalAssistance" && (
                            <TechnicalAssistance />
                          )}
                          {selectedRequestTypeValue === "ReportIssue" && (
                            <ReportIssue />
                          )}
                          {selectedRequestTypeValue === "ContactSales" && (
                            <ContactSales />
                          )}
                          {selectedRequestTypeValue === "Other" && (
                            <OtherEnquiries />
                          )}
                        </li>
                      </ul>
                    </section>

                    <div className="ms-clear" />
                    <div className="ms-PartSpacingVertical" />
                  </div>
                </div>
              </section>

              {/* {OtpModalOpen &&<OtpInputModal ModalHeading={""} setOpenOtpModal={setOtpModalOpen} handleOtp={handleOtp} value={OtpDataFromModal}  verifyOtp={verifyOtp} credentialRecord={""} otpInputModelOpenendForAction={otpInputModelOpenendForAction}/>} */}
            </div>
            <Outlet />
          </div>
        );
    
}

export default ServiceRequest;





