import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState} from "react";
import { Button } from "react-bootstrap";
import "./InfoDeleteProfile.css";
function InfoDeleteProfile ({
  modalHeading,
  setInfoModelOpen,
  displayMessage,
  action,
  infoOkButtonAction
}) {
 
  const [showModal, setShow] = useState({ setInfoModelOpen });
  const handleClose = () => {
    setInfoModelOpen(false);
  };
  const handleShow = () => setShow(true);
 
  return (
    <div
      className="modalBackground"
      style={{ opacity: "1", width: "100vw", height: "100vh" }}
    >
      <Modal
        animation={false}
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard="False"
        dialogClassName="my-modal"
      >
        <Modal.Header className="apim-pop-header" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalHeading}
          </Modal.Title>
          <div class="rightSec-head">
            <a href="javascript:;" class="pop-close-wrap mpa-button-cancel">
              <span class="icon-remove"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-input-wrapp has-validation">
           {displayMessage}
          </div>
        </Modal.Body>
        <Modal.Footer>
        {action === "SRQ" ? (
            <Button
              onClick={() => {
                infoOkButtonAction(action);
                setInfoModelOpen(false);
              }}
              id="oKButton"
            >
              Create Service Request
            </Button>
          ) : (
            <Button
              onClick={() => {
                infoOkButtonAction(action);
                setInfoModelOpen(false);
              }}
              id="oKButton"
            >
              Confirm
            </Button>
          )}

          <Button
            onClick={() => {
              setInfoModelOpen(false);
            }}
            id="cancleButton"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default InfoDeleteProfile;
