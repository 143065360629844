import {COMPANIES_API} from '../Config/AppConfiguration';
import axios from 'axios';
import Cookies from 'universal-cookie';
class CompanyService{

    getAccesssToken(){ 
        const cookies = new Cookies();
        var BEARER_TOKEN = 'Bearer '.concat( cookies.get("accessToken")); 
        return BEARER_TOKEN;
    }
    inviteRepresentative(companyId, requestData, confirmationCode){
        const URL=COMPANIES_API.concat(companyId).concat('/representatives/');
        return  axios.post(URL,requestData,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} });
    }

    replaceRepresentative(companyId, requestData,replacedRepId, confirmationCode){
        const URL=COMPANIES_API.concat(companyId).concat('/representatives/').concat(replacedRepId);
        return  axios.patch(URL,requestData,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} });
    }
    getRepresentativeEndorsementDetails(companyId, repId)
    {
        const URL=COMPANIES_API.concat(companyId).concat('/representatives/').concat(repId).concat('/');
        return  axios.get(URL,{ headers: {'Content-Type': 'application/json'} });
    }
    }
    export default new CompanyService();