const  ServiceRequestModel= {
  id: null,
  requestId: null,
  companyId: null,
  projectId: null,
  created: null,
  createdBy: null,
  requestType: null,
  requestText: null,
  subjectExistingUser: {
    id: null,
    displayName:null,
    email: null,
    mobile: null,
    accountStatus: null
  },
  subjectNewUser: {
    id: null,
    displayName: null,
    email: null,
    mobile: null,
    accountStatus: null
  },
  subjectCompany: {
    id: null,
    name: null,
    countryCode: null,
    registrationCode: null,
    description: null,
    representatives: [
      {
        id: '',
        name: "",
        isConfirmed: Boolean,
        email: "",
        endorsedBy: "",
        endorsedByEmail: "",
        lastLogin: "",
        invitees: [
          {
            memberId: '',
            representativeId: '',
            memberName: "",
            memberEmail: "",
            memberOfProjects: ""
          }
        ]
      }
    ],
    projects: [
      {
        id: 0,
        name: "",
        projectMembers: [
          {
            id: 0,
            name: "",
            email: "",
            invitedBy: "",
            lastLogin: ""
          }
        ],
        representatives: [
          {
            id: 0,
            name: "",
            isConfirmed: Boolean,
            email: "",
            endorsedBy: "",
            endorsedByEmail: "",
            lastLogin: "",
            invitees: [
              {
                memberId: 0,
                representativeId: 0,
                memberName: "",
                memberEmail: "",
                memberOfProjects: ""
              }
            ]
          }
        ]
      }
    ],
    userCanEditDescription: Boolean,
    userCanAddProject: Boolean,
    userCanAddDelegate: Boolean,
    userCanRemoveDelegate: Boolean,
    userCanSRQManageCompany: Boolean
  }
}
export default ServiceRequestModel;