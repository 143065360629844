import axios from 'axios';
import {APP_URL, USER_CURRENT_API} from '../Config/AppConfiguration';
import Cookies from 'universal-cookie';

class SRQService{

    getUserAccessToken(){
        const cookies = new Cookies();
        return cookies.get("accessToken");
    }
    
createNewServiceRequest(srqObject,confirmationCode){
    const URL=USER_CURRENT_API.concat('/serviceRequests/');
    return  axios.post(URL,srqObject ,{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })
}

getAllServiceRequests(){
        const URL=USER_CURRENT_API.concat('/serviceRequests/');
        return  axios.get(URL, { headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':0} })
    }


}
export default new SRQService();