import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
function ProdAccessInfoDialog ({setInfoModelOpen}) {
 
   const infoTextStyle={
      fontFamily: '"UniNeueRegular", sans-serif',
      fontSize: '15.1px',
      lineHeight: '28px',
      fontWeight: '400',
      fontStyle: 'normal',
      textAlign: 'left',
      wordWrap: 'break-word',
      color: '#333333'
  };
  const [showModal, setShow] = useState({ setInfoModelOpen });
  const handleClose = () => {
    setInfoModelOpen(false);
  };
  const handleShow = () => setShow(true);
 
  return (
    <div
      className="modalBackground"
      style={{ opacity: "1", width: "100%", height: "500vh" }}
    >
      <Modal
        animation={false}
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard="False"
        dialogClassName={'modal-xl'}
      >
       
        <Modal.Body>
        
          <div className="custom-input-wrapp has-validation mt-3" style={infoTextStyle}>
         
          <div>
            <p>Requesting production access freezes the selection of APIs. When your access request has been completed, you and the company representative(s) will be notified and the representative will be able to generate and retrieve production credentials.</p>
            <p>Access to test environments is not affected and you will not lose the ability to test changes to your application.</p>
          </div>
          </div>
          <div>
        <Button
            onClick={() => {
                setInfoModelOpen(false);
            }}
            id="closeButton"
            className="ui button comm-button-style primary apim-pop-button-create float-end "

          >
            CLOSE
          </Button>
        </div>
        </Modal.Body>
        
       
      </Modal>
    </div>
  );
}
export default ProdAccessInfoDialog;
