import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { confirmable, createConfirmation } from "react-confirm";

  const apimPopHeader = {
    fontSize: '20px',
    lineHeight: '24px',
    background: '#2d32aa',
    textTransform: 'capitalize',
    color: '#fff',
    fontWeight: 'bold'
}
  const popupInfo = {
    fontSize: '16.5px',
    lineHeight: '26px',
    color: '#2d32aa',
    margin: '5px 0 35px 0'
  };
const ConfirmationPopup = ({
  okLabel = "Confirm",
  cancelLabel = "Cancel",
  title,
  confirmation,
  show,
  proceed,
  enableEscape = true
}) => {
  return (
    
    <div style={{}}>
      <Modal
        animation={false}
        show={show}
        onHide={() => proceed(false)}
        backdrop={enableEscape ? true : "static"}
        keyboard={enableEscape}
        dialogClassName={'modal-lg'}
        centered
      >
        <Modal.Header  >
          <Modal.Title>
            <span style={apimPopHeader}>{title}</span>
          </Modal.Title>
          
          <div className="col-sm">
            <a href='javascript:;' className="pop-close-wrap mpa-button-cancel bi bi-x-lg"  onClick={() => proceed(false)}/>
           
        </div>
          
        </Modal.Header>
        <Modal.Body><p style={popupInfo}>{confirmation}</p></Modal.Body>
        <Modal.Footer>
          <Button
            className=" comm-button-style primary float-end "
            bsStyle="primary"
            onClick={() => proceed(true)}
          >
            {okLabel}
          </Button>
          <Button 
          className=" comm-button-style float-end "
          onClick={() => proceed(false)}>{cancelLabel}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

ConfirmationPopup.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool
};

export function confirm(
  title,
  confirmation,
  proceedLabel = "Confirm",
  cancelLabel = "cancel",
  options = {}
) {
  return createConfirmation(confirmable(ConfirmationPopup))({
    title,
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options
  });
}
