export const APP_URL = process.env.REACT_APP_API_ENDPOINT;

export const SIGNUP_URL =process.env.REACT_APP_SIGNUP_COMPONENT_URL;
export const REDIRECT_URL= process.env.REACT_APP_REDIRECT_URL;
export const AUTH_URL=process.env.REACT_APP_AUTH_URL;
export const SIGNUP_STATIC_TOKEN = process.env.REACT_APP_SIGNUP_COMPONENT_TOKEN;
export const RECAPTCHA_SITE_KEY= process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const REDIRECT_URL_DEVELOPER= REDIRECT_URL.concat('/developer');
export const REDIRECT_URL_PROJECT= REDIRECT_URL.concat('/projects');
export const REDIRECT_URL_CHPWD= REDIRECT_URL.concat('/changePassword');

export const USER_CURRENT_API = APP_URL.concat('/users/current');
export const PROJECTS_API = APP_URL.concat('/projects/');
export const COMPANIES_API = APP_URL.concat('/companies/');
export const MEMBERS_API = APP_URL.concat('/projects/{}/members/')
export const CHANGE_PASSWORD= APP_URL.concat('/users/current/password')

export const SIGNUP_USER_API = SIGNUP_URL.concat('/users');

export const SIGNUP_RESET_PASSWORD_API= SIGNUP_URL.concat('/users/resetpassword');
export const STS_LOGOUT_URL= process.env.REACT_APP_STS_LOGOUT_URL;
export const IDENTITY_LOGOUT_URL= process.env.REACT_APP_IDENTITY_LOGOUT_URL;

export const NETS_MAIN_WEBSITE_URL= process.env.REACT_APP_NETS_MAIN_WEBSITE_URL;
export const FOOTER_NETS_EU_HREF_URL= process.env.REACT_APP_FOOTER_NETS_EU_HREF_URL;
export const FOOTER_PRIVACY_POLICY_HREF_URL= process.env.REACT_APP_FOOTER_PRIVACY_POLICY_HREF_URL;
export const FOOTER_GENERAL_TC_HREF_URL= process.env.REACT_APP_FOOTER_GENERAL_TC_HREF_URL;
