import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ModiFyRegistrationDetails.css'
import './TechnicalAssistance.css'
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {Popover} from 'react-bootstrap'
import ProjectsService from '../../Services/ProjectsService';
import ServiceRequestModel from './ServiceRequestModel';
import OtpInputModal from '../Company/OtpInputModal';
import SRQService from '../../Services/SRQService';
import Modal from 'react-bootstrap/Modal';
import './APIVersionException.css';
import { ToastContainer, toast ,Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ReportIssue = () => {



    const navigate = useNavigate();
    const pageReload = () => {
      // 👇️ navigate to /
      window.location.reload();
    };
    const parseLines = (value) => value.replace(/(\\n)/g, "");
      const [isCreateSRQButtonActive, setIsCreateSRQButtonActive] = useState(false)
      //otpinput
      const [OtpModalOpen, setOtpModalOpen] = useState(false);
      const[OtpDataFromModal,setOtpDataFromModal] = useState([]);
      //Confirmation modal
      const [modalShow, setModalShow] = React.useState(false);
      var otpInputModelOpenendForAction = '';
    
    const [selectedRequestTypeCompany, setSelectedRequestTypeCompany] = useState('');
    const [selectedRequestTypeProject, setSelectedRequestTypeProject] = useState('');
    
    const [existingCompany, setExistingCompany] = useState([]);
    const [existingProject, setExistingProject] = useState([]);
    const [accountCountryCode, setAccountCountryCode] = useState("");
    //AdditionalDetails
    const [additionalDetails, setAdditionalDetails] = useState('')
    const [randomText, setRandomText] = useState('')
    
    const [accountCheck, setAccountCheck] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    
    const [email, setEmail] = useState("");
    const [displayName, setDisplayName] = useState("");
    
      const [message, setMessage] = useState(" ");
      const additionalDetailsMessage = "Please provide the details of the issue you have observed and if possible, steps to reproduce."
    
      const handleOtp = (OtpDataToProject) => {
        setOtpDataFromModal(OtpDataToProject);
      }
      //confirmation modal view
      function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
            backdrop='static' 
            keyboard="False"
            dialogClassName='my-modal'
           
    
    
          >
            <Modal.Header className='modal-header'>
              <Modal.Title id="contained-modal-title-vcenter">
              Confirmation on  service request
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
              <p>
              Request accepted for ReportIssue, request number shall be updated soon!</p>
            </Modal.Body>
            <Modal.Footer>
            <button className="ui button comm-button-style primary" onClick={onServiceRequestCreated}>OK</button>
            </Modal.Footer>
          </Modal>
        );
      }
      function clearAllFields()
      {
        setSelectedRequestTypeCompany('');
        setSelectedRequestTypeProject('');
       setAdditionalDetails('');
      }
      const onServiceRequestCreated =  ()  =>{
        console.log("inside onServiceRequestCreated function")
        clearAllFields();
        setModalShow(false);
        window.location.href="/viewserviceRequests";
        
        
       
      };
    const handleSelectChange = (event) => {
      let eValue = event.target.value;
      setSelectedRequestTypeCompany(eValue);
      if (eValue == "Select Existing Company") {
        setIsDisabled(true);
      }else{
        setIsDisabled(false);
      }
    }
    const handleClearCompanySelection = () => {
      setSelectedRequestTypeCompany("");
    };
    const handleClearProjectSelection = () => {
      setSelectedRequestTypeProject("");
    };
    
    const handleSelectProject = (event) => {
        let eValue = event.target.value;
        setSelectedRequestTypeProject(eValue);
        if (eValue == "Select Existing Project") {
          setIsDisabled(true);
        }else{
          setIsDisabled(false);
        }
      }
    
      console.log("SELECTED COMPANY ID FROM DROPDOWN::"+selectedRequestTypeCompany);
      console.log("get all projects response:-->"+selectedRequestTypeProject)
      console.log("get all projects response:-->"+additionalDetails)
      
    
      async function verifyOtp(OtpDataFromModal){
      
        if(OtpDataFromModal.length!==0){
        
          console.log(OtpDataFromModal)
          OtpDataFromModal  = Number(OtpDataFromModal.join(''));
          //alert("otp:"+OtpDataFromModal)
          // ServiceRequestModel.companyId=null;
  
          // ServiceRequestModel.projectId=null;
          ServiceRequestModel.requestType="ReportIssue";
          // ServiceRequestModel.requestText=additionalDetails;
          // ServiceRequestModel.requestText=randomText.split('\n');
          ServiceRequestModel.requestText=additionalDetails.replace(/(?:\r\n|\r|\n)/g, ' ');
          
          ServiceRequestModel.companyId=selectedRequestTypeCompany;
          ServiceRequestModel.projectId=selectedRequestTypeProject;
    
          console.log(ServiceRequestModel);
           
         // alert("userDetailsObject.password"+userDetailsObject.password);
         try {
          //alert("verifyOtpforCreateCtedential"+OtpDataFromModal);
          // let temp= createCompanyDefaultRequestBuilder();
          // alert(temp.);
             const response = await SRQService.createNewServiceRequest(ServiceRequestModel,OtpDataFromModal);
             if(response)
             {
              setOtpModalOpen(false);
              setModalShow(true);
             }
                  } catch (error) {
                    setOtpModalOpen(true);
                    toast.error(error.response.data.message, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      autoClose: 8000, //6 seconds
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      transition: Slide
                    });
              }
          
          
        
         }
        
          }
    
          async function isValid() { 
           if(selectedRequestTypeCompany!==null && selectedRequestTypeProject!==null &&
             additionalDetails!==null)
             return false;
          }
    async function createSRQ(){
    
            
        console.log("HI FROM Creeate company")
        //e.preventDefault();
        setIsCreateSRQButtonActive(true);
        //userDetailsObject.password=password;
       
        
        otpInputModelOpenendForAction = "registerCompany";
        setOtpModalOpen(true);
        
        // 
        try {
            const response = await ProjectsService.getStrongConfirmationCode("POST",null,"reportIssue",null,null,null);
        if (response && response.data.channel == "SMS") {
            otpInputModelOpenendForAction = "reportIssue";
            
            
            setOtpModalOpen(true);
           // alert("otp entered from otp modal for deleting credentials is ---->" + OtpDataFromModal);
          }
       
            
        } catch (error) {
            console.log(error);
        }
       // setModalShow(true);  //enable here for modal testing
       
      
          }
          const resendOtp = async() => {
            await ProjectsService.getStrongConfirmationCode("POST",null,"reportIssue",null,null,null);
          }

       useEffect(() => {
            ProjectsService.getAllProjects().then((response) => {
              console.log("getAllProjects response------->"+response.data[0].companyProjects.map((e)=>e.name))
              setExistingCompany(response.data[0].companyProjects);
              
            }).catch(error =>{
              console.log(error);
            });
          },[])
    
         
          const customstyles = {
            dropdownindicator: base => ({
              ...base,
              fill: "red" // custom colour
            })
          };
          const popover = (data) =>(  
      
            <Popover id="popover-basic" style={{minWidth: '300px', textAlign: 'center', borderColor:'black',borderStyle:'solid'}}>    
              <Popover.Body className='row'>  
                <p  className='row'> {data}</p>
              </Popover.Body>  
            </Popover>  
          ); 
    
    
        return (
          <>
            <li>
              <div className="sec">
                <div className="head-titlw-w head-titl-unset" style={{maxWidth: '26%'}}>
                  <h3
                    style={{ fontSize: "16px" }}
                    className="sec-title"
                    id="ReportIssue-promptForModifyCompany"
                    data-info=""
                  >
                    REPORT ISSUE
                  </h3>
                </div>
                <ul className="serv-req sec-inner-wrap">
                  <li>
                    <div
                      className="dropdown-w-info mt-3"
                      id="selectReportIssueWrapper"
                      style={{ display: "flex" }}
                    >
                      {selectedRequestTypeCompany ? (
                      
                      <span 
                      id='clear-icon-company'
                        className="clear-icon-company no-ligatures"
                        onClick={handleClearCompanySelection}
                      >
                        &times;
                      </span>
                    ) : (
                      <i className="dropdown icon" />
                    )}
                      <div className="width-full">
                        <Form.Select
                          defaultValue="Select Existing Company"
                          value={selectedRequestTypeCompany}
                          onChange={handleSelectChange}
                          styles={customstyles}
                          style={{ width: "100%" }}
                          className={selectedRequestTypeCompany ? 'dropdown-with-close' : 'dropdown-default'}
                        
                        >
                          <option className="textdefault">
                            {"Select Existing Company"}
                          </option>
    
                          {existingCompany &&
                            existingCompany.sort((a,b)=> a.name > b.name? 1 : -1).map((cp) => {
                              //alert(cp.name);
                              return <option value={cp.id}>{cp.name}</option>;
                            })}
                        </Form.Select>
    
                        <span className="required-field-op">(Required)</span>
                      </div>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={popover(
                          "Please enter the company this request relates to."
                        )}
                      >
                        <a
                          className="info-icon-w ms-2"
                          id="infoPopOverModifyCompany"
                          target="_blank"
                        >
                          <span className="bi bi-info-circle mt-2" />
                        </a>
                      </OverlayTrigger>
                    </div>
                  </li>
                  <li>
                    <div
                      className="dropdown-w-info mt-3"
                      id="selectreportIssueWrapper"
                      style={{ display: "flex" }}
                    >
                       {selectedRequestTypeProject ? (
                      
                      <span 
                      id='clear-icon-project'
                        className="clear-icon-project no-ligatures"
                        onClick={handleClearProjectSelection}
                      >
                        &times;
                      </span>
                    ) : (
                      <i className="dropdown icon" />
                    )}
                      <div className="width-full">
                        <Form.Select
                          defaultValue="Select Existing Project"
                          value={selectedRequestTypeProject}
                          onChange={handleSelectProject}
                          styles={customstyles}
                          style={{ width: "100%" }}
                          disabled={isDisabled}
                          className={selectedRequestTypeProject ? 'dropdown-with-close' : 'dropdown-default'}
                        >
                          <option className="textdefault">
                            {"Select Existing Project"}
                          </option>
                          
                          {existingCompany &&
                            existingCompany.filter((cp)=> cp.id==selectedRequestTypeCompany).map((companyProjects) => {
                              //alert(cp.name);
                              return ( 
                                companyProjects.projects.sort((a,b)=> a.name > b.name? 1 : -1).map( cproject=> {
                                  return <option value={cproject.id}>{cproject.name}</option>
                              
                                }))
                              })}
    
                          
                        </Form.Select>
    
                        <span className="required-field-op">(Required)</span>
                      </div>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={popover(
                          "If applicable, please enter the project where you observed the issue."
                        )}
                      >
                        <a
                          className="info-icon-w ms-2"
                          id="infoPopOverReportIssue"
                          target="_blank"
                        >
                          <span className="bi bi-info-circle mt-2" />
                        </a>
                      </OverlayTrigger>
                    </div>
                  </li>
                  <li>
                          <div className="custom-input-wrapp mt-3 mb-3">
                            <div className="dropdown-w-info">
                              <div className="width-full">
                                <textarea
                                  className="cust-text-a"
                                  maxLength={4000}
                                  //wrap='soft'
                                  placeholder="Additional Details"
                                  id="textAreaModifyAdditionalDetails"
                                  tabIndex={1}
                                  value={additionalDetails}
                                  onChange={(e) =>
                                    setAdditionalDetails(e.target.value)
                                  }
                                  disabled={!selectedRequestTypeProject || selectedRequestTypeProject===""}
                                />
                               
    
                                <span className="required-field-op">
                                  (Required)
                                </span>
                              </div>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={popover(
                                   additionalDetailsMessage 
                                )}
                              >
                                <a
                                  className="info-icon-w pop-common-sel sr-pophover visible"
                                  id="infoPopOverModifyAdditionalDetails"
                                  style={{ marginLeft: 5, visibility: "visible" }}
                                  target="_blank"
                                >
                                  <span className="bi bi-info-circle mt-2" />
                                </a>
                              </OverlayTrigger>
                            </div>
                          </div>
                         
                        </li>
    
                  
                </ul>
              </div>
            </li>
            <li className="apim-pop-button-w comm-button-wrap ser-req-btn">
              <button
                className="button comm-button-style primary btn-disable"
                id="btnSubmitReportIssue"
                tabIndex={1}
                onClick={() => createSRQ()}
                disabled={!selectedRequestTypeCompany ?true:(!selectedRequestTypeProject? true:(!additionalDetails?true:false))}
              >
                Create
              </button>
              <button
                className="button comm-button-style secondary"
                id="btnLoaderReportIssue"
                style={{ display: "none" }}
              >
                <i className="icon-plus iconLoader" />
                Creating Service Request..
              </button>
              {/* <button
                className="button comm-button-style secondary"
                id="btnCancelReportIssue"
                tabIndex={1}
                onClick={pageReload}
              >
                Cancel
              </button> */}
               <a
              className="button comm-button-style secondary"
              href="/serviceRequest"
            >
              Cancel
            </a>
            </li>
            <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
      <div className="toast-container"><ToastContainer limit={2}/></div>
      {OtpModalOpen &&<OtpInputModal ModalHeading={""} setOpenOtpModal={setOtpModalOpen} handleOtp={handleOtp} value={OtpDataFromModal}  verifyOtp={verifyOtp} credentialRecord={""} otpInputModelOpenendForAction={otpInputModelOpenendForAction} resendOtp={resendOtp}/>} 
    
          </>
        );
       
        
    }
    export default ReportIssue;