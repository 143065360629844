import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./MembersModal.css";
function InformationDialog ({
  modalHeading,
  setInfoModelOpen,
  displayMessage,
  setScrollToCredentialView
}) {
 
  const [showModal, setShow] = useState({ setInfoModelOpen });
  
  const handleClose = () => {
    setInfoModelOpen(false);
  };
  const handleShow = () => setShow(true);
 
  return (
    <div
      className="modalBackground"
      style={{ opacity: "1", width: "100vw", height: "100vh" }}
    >
      <Modal
        animation={false}
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard="False"
        dialogClassName="my-modal"
      >
        <Modal.Header className="apim-pop-header">
          <Modal.Title id="contained-modal-title-vcenter">
            {modalHeading}
          </Modal.Title>
          <div className="col-sm d-flex justify-content-end">
            <a href='javascript:;' className="pop-close-wrap mpa-button-cancel bi bi-x-lg"  onClick={() => setInfoModelOpen(false)}/>
        </div>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-input-wrapp has-validation customDisplayMessageRemoveApi">
           {displayMessage}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
           className="ui button comm-button-style primary"
            onClick={() => {
              setScrollToCredentialView(true);
                setInfoModelOpen(false);

            }}
            id="okButton"
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default InformationDialog;
