import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {useState, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import './UploadProperties.css';

function UploadProperties({setOpenModal,ModalHeading,propValueSet,updateHandlerProps}) {
  const [showModal, setShow] = useState({setOpenModal}); 
  const handleClose = () => setShow(false); 
  const handleShow = () => setShow(true);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  async function handleChange(e){
    setSelectedFile(e.target.files[0])
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => { 
      const text = (e.target.result)
      propValueSet(text);
      setUploadedFileData(text);
    };
    reader.readAsText(e.target.files[0])
  }

  const handleUpload = async () => {
     if (!selectedFile) {
        alert("Please first select a file");
        return;
     }
     updateHandlerProps(null);
     setOpenModal(false);
  };

  return(
    <div className="modalBackground" style={{opacity:'1',width: '100vw',
    height: '100vh'}}>
    <Modal
      //{...props}
      animation={false}
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter" 
      centered
      backdrop='static' 
       keyboard="False"
    >
      <Modal.Header className='apim-pop-header' closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         {ModalHeading}
        </Modal.Title>
        <div class="rightSec-head">
            <a href="javascript:;" class="pop-close-wrap mpa-button-cancel">
            <span class="icon-remove"></span></a>
        </div>
      </Modal.Header>

      <Modal.Body>

   <div className="mb-3">
        <input className="form-control" 
               id="SmallFile"
               type="file" onChange={handleChange} multiple/>
<div className="">
<p>{uploadedFileData}</p>
</div>
</div>
      
          
         
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
               handleUpload();
             }} id=''>Create</Button>

<Button onClick={() => {
               setOpenModal(false);
             }} id='closeButton'>Cancel</Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
  
      
}
export default UploadProperties