import React, { useState, useEffect,useRef} from 'react'; 
import DataTable from "react-data-table-component";
import "./ActivityLog.css";
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import FormSelect from 'react-bootstrap/FormSelect';
import SRQService from '../../Services/SRQService';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Cookies from 'universal-cookie';
import axios from 'axios';
import ProjectsService from '../../Services/ProjectsService';
import ActivityLogService from '../../Services/ActivityLogService';
import { Button, Popover, OverlayTrigger} from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { locale } from 'moment/moment';


import uiCloseSVG from '../../Assets/Images/ui-close.svg'
const ActivityLog = () => {

  const popoverRefreshAndLoad = (data) =>(  
  
    <Popover id="popover-basic-activityLog" className="custom-popover" style={{backgroundColor:'#2d32aa',border:'1px solid #2d32aa'}}>    
      <Popover.Body className='row' style={{marginLeft:'2px',marginTop:'1px',color:'white',fontSize:'16px'}}>  
       {data}
      </Popover.Body>  
    </Popover> 
    
  ); 
   
    const customStyles = {
        header: {
            style: {
                minHeight: '100px',
                color: '#2d32aa',
                borderRadius: 0,
                borderLeft: 'none',
                borderRight: 'none',
                background: '#f6f7f9',
                fontSize: '35px',
                textAlign: 'left',
                fontWeight: '600px',
            },
        },
        headRow: {
            style: {
                background: '#2d32aa',
                color: '#fff',
                padding: '14px ​12px',
                borderRadius: 0,
                fontWeight: 'normal',
                borderTop: '1.5px solid #3e6a85',
                borderBottom: '1.5px solid #3e6a85',
                borderLeft: 'none',
                minWidth: '120px',
                fontSize: '14.1px',
            },
        },
        headCells: {

            style: {
                '&:not(:last-of-type)': {
                },
                textAlign: 'left',
            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    //paddingBottom: '10px',
                    color: '#000',
                      textAlign: 'left',
                      padding: '14px ​12px',
                      opacity: '21',
                      fontSize: '13px',
                },
            },
        },
    };
    const [logData, setLogData] = useState([]);
	  const [loading, setLoading] = useState(false);
    const [loadActivityLogDataTable, setLoadActivityLogDataTable] = useState(false);
    const [isSystemLogEnabled, setIsSystemLogEnabled] = useState(false);
    const [startDate, setStartDate] = useState();
    //const [endDate, setEndDate] = useState();
    const [endDate, setEndDate] = useState();
    const [isoStartDate, setIsoStartDate] = useState();
    //const [endDate, setEndDate] = useState();
    const [isoEndDate, setIsoEndDate] = useState();

    const [existingCompany, setExistingCompany] = useState([]);
    const [selectedRequestTypeCompany, setSelectedRequestTypeCompany] = useState('');// selected companyId
    const [selectedRequestTypeProject, setSelectedRequestTypeProject] = useState('');// selected projectId
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [projectName, setProjectName] = useState("");

    const [isDisabled, setIsDisabled] = useState(true);

    const [scopes, setScopes] = useState([
      'All Activities',
      'Project Management',
      'All User Management',
      'Privileged User Management',
      'Production Access',
    ]);

    const [selectedScope, setSelectedScope] = useState('All Activities');
    const [companyId, setCompanyId] = useState();
    const [calendarDate, setCalendarDate] = useState(new Date());
    const startDateCalendarRef = useRef(null);
    const endDateCalendarRef = useRef(null);
    const [showStartDateCalendar, setShowStartDateCalendar] = useState(false);
    const [showEndDateCalendar, setShowEndDateCalendar] = useState(false);
    const formattedStartDate = startDate? `${startDate.getDate().toString().padStart(2, '0')}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()}`:"";
    const formattedEndDate = endDate? `${endDate.getDate().toString().padStart(2, '0')}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getFullYear()}`:"";    const [filterReferees, setFilterReferees] = useState(false);

    const [filterCompanyName, setFilterCompanyName] = useState("");
    const [filterProjectName, setFilterProjectName] = useState("");
    const [filterScopes, setFilterScopes] = useState('All Activities');
    let queryParams  = {
      scope:'',
      includeSystemLogs:'false',
      periodFrom:'',
      periodTo:'',
      limit:101,

     };
     const toggleStartDateCalendar = () => {
      setShowStartDateCalendar(!showStartDateCalendar);
    };
  
    const toggleEndDateCalendar = () => {
      setShowEndDateCalendar(!showEndDateCalendar);
    };
   
    
    useEffect(() => {

      ProjectsService.getAllProjects().then((response) => {
        setExistingCompany(response.data[0].companyProjects);
      }).catch(error =>{
        console.log(error);
      });
      
	}, []);
 

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        startDateCalendarRef.current &&
        !startDateCalendarRef.current.contains(event.target)
      ) {
        setShowStartDateCalendar(false);
      }
      if (
        endDateCalendarRef.current &&
        !endDateCalendarRef.current.contains(event.target)
      ) {
        setShowEndDateCalendar(false);
      }
    };

    document.body.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.body.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []); 

  
  useEffect(() => {
    if(filterReferees){
    fetchActivityLogs();
    setFilterReferees(false);
    }
  },[filterReferees])


  const handleSelectChange = (event) => {
    let eValue = event.target.value;
    if (eValue == "Select Existing Company") {
      setSelectedRequestTypeCompany(eValue);
      setSelectedCompany("");
      setCompanyName("");
      setIsDisabled(true);
    } else {
      setSelectedRequestTypeCompany(eValue);
      let eData = existingCompany.filter((record) => eValue == record.id);
      setSelectedCompany(eData);
      setCompanyName(eData[0].name);
      setIsDisabled(false);
    }
    //setLoadActivityLogDataTable(false);
    };
    const handleSelectProject = (event) => {
      let eValue = event.target.value;
      let exCompanyObject = existingCompany.filter((record) => selectedRequestTypeCompany == record.id);
      var pName = exCompanyObject[0].projects.filter((record) => eValue == record.id).map((e)=>{return e.name});
      //alert(JSON.stringify());
      setProjectName(pName);
      //filter((record) => eValue == record.id)
      //let name12 = JSON.stringify(eData);
      //console.log('--------------'+name12);
      //alert(name12);
      setSelectedRequestTypeProject(eValue);
      //setProjectName(pName);
      if (eValue == "Select Existing Project") {
        setIsDisabled(true);
      }else{
       // let eData = existingCompany.projects.filter((record) => eValue == record.id);
        
        setIsDisabled(false);
      }
      //setLoadActivityLogDataTable(false);
    }


    const handleSelectScope = (event) => {
      let eValue = event.target.value;
      setSelectedScope(eValue);
      //setLoadActivityLogDataTable(false);
    }

    const clearProjectFilter = async () => {
      //setSelectedRequestTypeProject("");
      //onClick={() => setSelectedRequestTypeProject("");}
        //fetchActivityLogs();
        setFilterReferees(true);
    }

    const clearAllFields = () => {
      setSelectedRequestTypeCompany("");
      setSelectedCompany("");
      setCompanyName("");
      setSelectedRequestTypeProject("");
      setSelectedScope("");
      setIsSystemLogEnabled(false);
      setIsDisabled(true);
      setStartDate("");
      setIsoStartDate("");
      setEndDate("");
      setIsoEndDate("");
      setLogData('');
      setLoadActivityLogDataTable(false);
    };
    const fetchActivityLogs = async() => {
      setFilterCompanyName(companyName);
      setFilterProjectName(projectName);
      setFilterScopes(selectedScope);
        setLoading(true);
        // queryParams.companyId=selectedRequestTypeCompany;
        // queryParams.projectId=selectedRequestTypeProject;
        queryParams.includeSystemLogs=isSystemLogEnabled;
        queryParams.scope=selectedScope;
        queryParams.periodFrom=isoStartDate;
        queryParams.periodTo=isoEndDate;
        if(!selectedRequestTypeProject)
        {
          const response = await ActivityLogService.getActivityLogsByCompanyId(selectedRequestTypeCompany,queryParams)
          .then(response => {
            
            console.log(response.data)
            setLogData(response.data.log);
        setLoadActivityLogDataTable(true);
         
          setLoading(false);
            console.log(response.data.log); 
       }).catch(error => {
            console.log(error); 
       });
        }
        else if(selectedRequestTypeProject)
        {
          const response = await ActivityLogService.getActivityLogsByProjectId(selectedRequestTypeProject,queryParams)
          .then(response => {
            
            setLogData(response.data.log);
            setLoadActivityLogDataTable(true);
         //setData({});
          setLoading(false);
            console.log(response.data); 
       }).catch(error => {
            console.log(error); 
       });

        }
        
    };
    const handleDateChange = (date,dateType) => {
      var isoDate ;
   
      
      if(dateType==="startDate")
      {
        const selectedDate = new Date(date);
        const utcDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));
        utcDate.setUTCHours(0, 0, 0, 0);
        setStartDate(utcDate);
        isoDate=utcDate.toISOString();
          setIsoStartDate(isoDate);
          console.log(isoDate);
          setShowStartDateCalendar(false); // Close the start date calendar


         //setting enddate to current date on selection of the start date
          const currentDate = new Date();
          console.log('Current Date:', currentDate);
          const currentUtcDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
          console.log('Current UTC Date:', currentUtcDate);

          setEndDate(currentDate);
          currentUtcDate.setUTCHours(23, 59, 59, 999);
          const isoEndDate = currentUtcDate.toISOString();
          setIsoEndDate(isoEndDate);
          
      }
      else if(dateType==="endDate")
      {
        const selectedDate = new Date(date);
        const utcDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));

    // Set the time to end of the day (23:59:59.999)
    utcDate.setUTCHours(23, 59, 59, 999);
    setEndDate(selectedDate);

    // Convert the UTC date to ISO string
    isoDate = utcDate.toISOString();
    setIsoEndDate(isoDate);
    setShowEndDateCalendar(false); // Close the end date calendar
      }
      
     
    };
    function getNumberOfPages(rowCount, rowsPerPage) {
        return Math.ceil(rowCount / rowsPerPage);
      }
      
      function toPages(pages) {
        const results = [];
      
        for (let i = 1; i < pages+1; i++) {
          results.push(i);
        }
      
        return results;
      }
      const popover = (data) =>(  
        
        <Popover id={`popover-positioned-bottom`}>  
                <Popover.Header as="h5"></Popover.Header>  
                <Popover.Body style={{color:'#a2a2a2'}}>  
                  {data}  
                </Popover.Body>  
              </Popover> 
      ); 
      
      const columns = [
        {
          name: "ID",
          selector: (row) => row.id,
          wrap: true,
          minWidth:"30px",
          sortable: true

        },
        {
          name: "Created",
          selector: (row) => row.creationDate,
          format: (row) => moment(row.creationDate).format("DD-MM-YYYY hh:mm:ss"),
          sortable: true,
          wrap: true,
          minWidth:"200px",
        },
        {
          name: "Action",
          selector: (row) => row.action,
          minWidth:"70px",
          wrap: true,
          //minWidth:"200px",
        },
        {
          name: "Performed By",
          selector: (row) => row.userName,
          minWidth:"100px",
          wrap: true,
          sortable: true

          //minWidth:"300px",
        },
        {
          name: "Performed On",
          selector: (row) => row.subjectUserName,
          //format: (row) => moment(row.createdDate).format("DD-MM-YYYY hh:mm:ss"),
          minWidth:"100px",
          sortable: true,

          wrap: true,
          //dataFormat:{dateFormatter}
          
          //right: true,
        },
        {
          name: "API Name",
          selector: (row) => row.apiName,
          minWidth:"100px",
          sortable: true,

          wrap: true,
          //right: true,
          
      
          
        },
        {
          name: "Property Name",
          selector: (row) => row.propertyName,
          //format: (row) => moment(row.createdDate).format("DD-MM-YYYY hh:mm:ss"),
          minWidth:"80px",
          sortable: true,

          wrap: true,
          //dataFormat:{dateFormatter}
          
          //right: true,
        },
        {
          name: "Service Request Ext Id",
          selector: (row) => row.srqExtId,
          //format: (row) => moment(row.createdDate).format("DD-MM-YYYY hh:mm:ss"),
          minWidth:"180px",
          sortable: true,

          wrap: true,
          //dataFormat:{dateFormatter}
          
          //right: true,
        },
        {
          name: "Credential",
          selector: (row) => row.credential,
          sortable: true,

          //format: (row) => moment(row.createdDate).format("DD-MM-YYYY hh:mm:ss"),
          minWidth:"80px",
          wrap: true,
          //dataFormat:{dateFormatter}
          
          //right: true,
        },
        {
          name: "Message",
          selector: (row) => row.message,
          //format: (row) => moment(row.createdDate).format("DD-MM-YYYY hh:mm:ss"),
          minWidth:"200px",
          sortable: true,

          wrap: true,
          //dataFormat:{dateFormatter}
          
          //right: true,
        }
      ];
      const customSort = (rows, selector, direction) => {
        return rows.sort((a, b) => {
            const aField = selector(a);
            const bField = selector(b);
    
            let comparison = 0;
    
            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }
    
            return direction === 'asc' ? comparison * -1 : comparison;
        });
    };
   
      
      // RDT exposes the following internal pagination properties
const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage
  }) => {
    const handleBackButtonClick = () => {
      onChangePage(currentPage - 1);
    };
  
    const handleNextButtonClick = () => {
      onChangePage(currentPage + 1);
    };
  
    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };
  
    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;
  
    return (
      <nav>
        <ul className="pagination justify-content-end pagination-large m-3 me-0">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
            >
              Previous
            </button>
          </li>
          {pageItems.map((page) => {
            const className =
              page === currentPage ? "page-item active" : "page-item";
  
            return (
              <li key={page} className={className}>
                <button
                  className="page-link"
                  onClick={handlePageNumber}
                  value={page}
                >
                  {page}
                </button>
              </li>
            );
          })}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    );
  };

  const handleChange = (e) => {
    setIsSystemLogEnabled(e.target.checked);
    
    }
  

       return (
       
        
        <div
            id="activityLogContainer"
            className="container-fluid"
            style={{ height: "100%" }}
          >
            <div
              className="row"
              style={{ minHeight: "10px",
              color: '#2d32aa'}}
            >
              <div className="empty-cont-header" style={{ minHeight: "10px" }}>
                <h1>VIEW ACTIVITIES LOG</h1>
              </div>
            </div>
         
            <div className="Activity-log-main-content"> 
            <div className="container first">
  
    
                    <section className="activity-log-list-w">
                      <section className="filter-w filter-company-w">
                        <div className="form-f-w activity-dd-wrapper">
                          <label htmlFor className="bold-label">Company Name</label>
                          <div
                            className="ui selection dropdown"
                            //id="activityCompanyDD"
                            tabIndex={0}
                          >
                            
                           
                            <Form.Select
                                  id='activityCompanyDD'
                                  defaultValue="Select Existing Company"
                                  value={selectedRequestTypeCompany}
                                  onChange={handleSelectChange}
                                  //styles={customstyles}
                                  style={{ width: "100%", borderRadius: 0 }}
                                >
                                 
                                  <option
                                    className="textdefault"
                                    selected
                                    value={"Select Existing Company"}
                                  >
                                    {"Select Existing Company"}
                                  </option>
                                  
                                  {existingCompany &&
                        existingCompany.map((cp) => {
                          //alert(cp.name);
                          return <option value={cp.id}>{cp.name}</option>;
                        })}
                                  
                                </Form.Select>
                            
                          </div>
                        </div>
                       
                        <div className="form-check form-switch ms-3 mt-4  activity-dd-wrapper">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="chkSystemLog"
                 //defaultChecked={notification[0].value === 'Yes' ? true : false}
                  checked={isSystemLogEnabled} 
                  style={{ fontSize: 25 }}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <label
                  className="form-check-label mt-1 bold-label"
                  htmlFor="includeSystemLogs"
                >
                  Include System Logs
                </label>
              </div>
                      </section>
                      <section className="filter-w filter-others-w">
                        <div className="form-f-w activity-dd-wrapper">
                          <label htmlFor className="bold-label">Project Name</label>
                          <div
                            className="ui selection dropdown upward disabled"
                            //id="activityProjectDD"
                            tabIndex={0}
                          >
                            <input type="hidden" name="activityProjectDD" />
                            <i className="dropdown icon" />
                            <Form.Select
                                  id='activityProjectDD'
                                  defaultValue="Select Existing Project"
                                  value={selectedRequestTypeProject}
                                  onChange={handleSelectProject}
                                  //styles={customstyles}
                                  style={{ width: "100%", borderRadius: 0 }}
                                  disabled={isDisabled}
                                >
                                 
                                  <option
                                    className="textdefault"
                                    selected
                                    value={"Select Existing Project"}
                                  >
                                    {"Select Existing Project"}
                                  </option>
                                  
                                  {existingCompany &&
                          existingCompany.filter((cp)=> cp.id==selectedRequestTypeCompany).map((companyProjects) => {
                            //alert(cp.name);
                            return ( 
                              companyProjects.projects.sort((a,b)=> a.name > b.name? 1 : -1).map( cproject=> {
                               return <option value={cproject.id} >{cproject.name}</option>
                            
                              }))
                            })}
                                  
                                </Form.Select>
                            
                            
                          </div>
                        </div>
                        <div className="form-f-w activity-dd-wrapper">
                          <label htmlFor className="bold-label">Scope</label>
                          <div
                            className="ui selection dropdown disabled"
                           // id="activityScopeDD"
                            tabIndex={0}
                          >
                            <input type="hidden" name="activityScopeDD" />
                            <i className="dropdown icon" />
                            <Form.Select
                                  defaultValue="select scope"
                                  id="activityScopeDD"
                                  value={selectedScope}
                                  onChange={handleSelectScope}
                                  //styles={customstyles}
                                  style={{ width: "100%", borderRadius: 0 }}
                                  disabled={isDisabled}
                                >
                                  <option
                                    className="textdefault"
                                    selected
                                    value={"Select Scope"}
                                  >
                                    {"Select Scope"}
                                  </option>
                                  {scopes &&
                        scopes.map((scope) => {
                          //alert(cp.name);
                          return <option value={scope}>{scope}</option>;
                        })}
                                  
                                </Form.Select>
                            
                            
                          </div>
                        </div>
                        <div className="form-f-w activity-dd-wrapper activity-cal-wrapper">
                              <label className="bold-label">Start date</label>
                                <div>
                                <input
          type="text"
          value={startDate ? formattedStartDate : ''}
          className='calendarInput'
          placeholder='Start'
          //disabled={isDisabled}
         
          onClick={toggleStartDateCalendar}
          readOnly
        />
       
      </div>  
      {showStartDateCalendar && (
          <div  ref={startDateCalendarRef} className='calendar-wrapper'>
          <Calendar
          className="custom-calendar"
           onChange={(date) => handleDateChange(date, 'startDate')} 
           value={startDate || new Date()} />
            
          </div>
        )}  
                          <br />
                        </div>
                        <div className="form-f-w activity-dd-wrapper activity-cal-wrapper">
          <label className="bold-label">End date</label>
              <div>
              <input
          type="text"
          className='calendarInput '
          placeholder='End'
          disabled={!startDate}
          value={endDate ? formattedEndDate : ''}
          onClick={toggleEndDateCalendar}
          readOnly
        />
       
        
      </div>
      {showEndDateCalendar && (
          <div  ref={endDateCalendarRef} className="calendar-wrapper">
          <Calendar 
            onChange={(date) => handleDateChange(date, 'endDate')} 
            value={endDate || new Date()} 
            className="custom-calendar"
          />
        </div>
          

        )}
              

     
      
              
            
          
        
      
    </div>
    
                        <div className="form-f-w list-table-btn"  disabled={isDisabled}>
                        <OverlayTrigger trigger="hover" placement="top"  overlay={popoverRefreshAndLoad("Refresh")} style={{pointerEvents:'none'}}>
                          <a
                            disabled={isDisabled}
                            href="javascript:;"
                            onClick={clearAllFields}
                            id="resetActivityFilters"
                            className="bi-arrow-clockwise disabled"
                            style={{fontWeight:'bolder'}}
                            data-inverted
                          >
                            
                          </a>
                          </OverlayTrigger>
                          <OverlayTrigger trigger="hover" placement="top"  overlay={popoverRefreshAndLoad("Submit")} style={{pointerEvents:'none'}}>
                          <a
                            disabled={isDisabled}
                            href="javascript:;"
                            
                            className="bi bi-arrow-right disabled"
                            style={{fontWeight:'bolder'}}
                            data-inverted
                            id="submitActivityLogFilter"
                            onClick={fetchActivityLogs}
                          >
                           
                          </a>
                          </OverlayTrigger>
                          
                        </div>
                      </section>
                      <section
                        className="activity-filter-wrapper"
                        id="activityFilterWrapper"
                        style={{}}
                      />
                      <div
                        className="ui pagination menu page-a-wrap"
                        id="activityPagination"
                        style={{ display: "none" }}
                      >
                        <a
                          className="disabled item"
                          target="_blank"
                          id="activityPrevPage"
                          title="Previous"
                        >
                          <span>&lt;</span>
                        </a>
                       
                        <a
                          className="disabled item"
                          target="_blank"
                          id="activityNextPage"
                          title="Next"
                        >
                          <span>&gt;</span>
                        </a>
                      </div>
                      {/* <span style="float: right;display:none" id="showTop500">Showing top 500 records</span> */}
                      <div
                        id="activityLogTableWrapper"
                        className="activity-log-wrapper"
                      />
                    </section>
                    {loadActivityLogDataTable && loadActivityLogDataTable===true?
        <div className='activityLogDataTable'>

<div className="d-flex gap-2 mb-3">
<span className="">Filters:</span>
{(selectedRequestTypeProject !='' && selectedRequestTypeProject!=null )?<>

<button type="button" className="activityFilterButton d-flex justify-content-center" onClick={clearAllFields}>
  <tr> 
    <td><img src={uiCloseSVG} width="12" height="12" className="opacity-50 me-1"/></td>
    <td><span >{filterCompanyName}</span></td>
  </tr>
</button>

              <button type="button" className="activityFilterButton d-flex justify-content-center" 
              onClick={() => {setSelectedRequestTypeProject("");setFilterReferees(true);}}>
                 <tr> 
    <td><img src={uiCloseSVG} width="12" height="12" className="opacity-50 me-1"/></td>
    <td><span >{filterProjectName}</span></td>
  </tr>
              </button>

</>:
<button type="button" className="activityFilterButton d-flex justify-content-center" onClick={clearAllFields}>
  <tr> 
    <td><img src={uiCloseSVG} width="12" height="12" className="opacity-50 me-1"/></td>
    <td><span >{filterCompanyName}</span></td>
  </tr>
</button>

}


              <button type="button" className="activityFilterButton d-flex justify-content-center"
              onClick={() => {setSelectedScope('All Activities');setFilterReferees(true);}}
              disabled={filterScopes==='All Activities'?true:false}
              >
                <tr> 
    <td><img src={uiCloseSVG} width="12" height="12" className="opacity-50 me-1"/></td>
    <td>< >{filterScopes}</></td>
  </tr>
              </button>

            
			  </div>



        <DataTable
         
          columns={columns}
          data={logData}
          paginationComponent={BootyPagination}
          highlightOnHover={true}
          customStyles={customStyles}
          progressPending={loading}
          sortFunction={customSort}
          defaultSortFieldId={5}
          pagination
        />
        </div>:<></>
}

                 
          </div>
          
        </div>
      </div>
    

          
       

    
);







}




export default ActivityLog;