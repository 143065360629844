import axios from "axios";
import Cookies from "universal-cookie";
import { APP_URL } from "../../Config/AppConfiguration";
import login from "./Login";
const AppAuthentication = () => {
  const cookies = new Cookies();

  axios.interceptors.request.use((config) => {
    if (config.url.includes(APP_URL)) {
      var token = cookies.get("accessToken");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
    }
    return config;
  });
  //|| error.response?.data?.message !== "Bad credentials."
 //(error.response?.request?.responseURL) &&
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        error.response?.status === 401 )
       {
        alert(
          "Your access token has expired, you will be re-directed to the login page"
        );
        window.location.href = login.getLoginUrl();
      }
      return Promise.reject(error);
    }
  );
};
export default AppAuthentication;
