
import Cookies from 'universal-cookie';
import { REDIRECT_URL_DEVELOPER,AUTH_URL } from "../../Config/AppConfiguration";

class Login {
 

  getLoginUrl = function () {

return (
   AUTH_URL +
   "&redirect_uri=" +
   encodeURIComponent(REDIRECT_URL_DEVELOPER) +
   "&nonce=" +
   btoa(Math.random()).slice(0, 24)
 )

    
  }

  loginAndRedirectToChangePassword = function (REDIRECT_URL_DEVELOPER) {
    console.log("redirecturl in chnagepassword method:---->"+REDIRECT_URL_DEVELOPER);
    var redirTo = REDIRECT_URL_DEVELOPER;
    var authUrl = AUTH_URL;
return (
   authUrl +
   "&redirect_uri=" +
   encodeURIComponent(redirTo) +
   "&nonce=" +
   btoa(Math.random()).slice(0, 24)
 )

  }

  currentUserTokenExpired = function () {
    var _user_attributes = new Cookies().get("currentAPIMUser");
    if (_user_attributes) {
      _user_attributes = decodeURI(_user_attributes);
      const currentUserFromCookie = JSON.parse(_user_attributes);
      if (currentUserFromCookie.accessTokenExpiry != null) {
        let accessTokenExpiryFromCookie = new Date(
          currentUserFromCookie.accessTokenExpiry
        );
        if (typeof accessTokenExpiryFromCookie.getMonth == "function") {
          if (accessTokenExpiryFromCookie > new Date()) {
            return false;
          }
        }
      }
    }
    return true;
  };

  getParameterByName = function (name) {
    // name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&#]" + name + "(=([^&#]*)|&|#|$)"),
      ful = regex.exec(window.location.href);
    if (!ful) return null;
    if (!ful[2]) return "";
    return decodeURIComponent(ful[2].replace(/\+/g, " "));
  };

  setCurrentUserObject = function (data, accessTokenExpiresIn) {
    return {
      accountStatus: data.accountStatus,
      displayName: data.displayName,
      email: data.email,
      id: data.id,
      lastLogin: data.lastLogin,
      mobileNumber: data.mobileNumber,
      accessTokenExpiry: new Date().setSeconds(accessTokenExpiresIn),
    };
  }
}
export default new Login();