import React, { useState, useEffect } from 'react';
import './EditCompany.css';
import {useParams} from "react-router-dom";
import ProjectsService from '../../Services/ProjectsService';
import { ToastContainer, toast ,Slide} from 'react-toastify';
import InviteRepresentative from './InviteRepresentative';
import OtpinputModal from './OtpInputModal';
import CompanyService from '../../Services/CompanyService';
import Modal from 'react-bootstrap/Modal';
import { confirm } from '../Allprojects/ConfirmationPopup';
import Form from 'react-bootstrap/Form';
import FormSelect from 'react-bootstrap/FormSelect';
import { useNavigate } from 'react-router-dom';

const EditCompany = () => {
  const { companyId} = useParams();
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [companyDescription, setCompanyDescription] = useState("");
  const [companies, setCompanies] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [OtpDataFromModal, setOtpDataFromModal] = useState([]);
  const [OtpModalOpen, setOtpModalOpen] = useState(false);
  const [invitedRepEmail, setInvitedRepEmail] = useState('');
  const [isReloadService, setIsReloadService] = useState(0);
 

  //replace representative
  const [replaceRepModalShow, setReplaceRepModalShow] = React.useState(false); 
  const [selectedNewRepresentativeId, setSelectedNewRepresentativeId] = useState();
  const [selectedNewRep, setSelectedNewRep] = useState({
    id:0,
     name:"",
     email:""
  });
  
  const [newRepEmail, setNewRepEmail] = useState('');
  const [newRepName, setNewRepName] = useState('');
  const [confirmedRepId, setConfirmedRepId] = useState(0);
  const [replacedUserInfo, setReplacedUserInfo] = React.useState({
    id:0,
     name:"",
     email:""
  }
  );
  const [otpInputModelOpenendForAction, setOtpInputModelOpenendForAction] = useState('');
  var requestType = 'ManageCompany';
  var requestSubType = 'ReplaceCRep';
  const navigate = useNavigate();
  const navigateToServiceRequestPage = (confirmedRepId) => {
    // 👇️ navigate to /  
    if (!companyId || !requestType || !requestSubType || !confirmedRepId) {
      console.error('Missing required parameters for navigation');
      return;
    }
    navigate(`/serviceRequest/${companyId}/${confirmedRepId}/${requestType}/${requestSubType}`);
  };
  useEffect(() => {
    ProjectsService.getCompaniesCrepDetails(companyId).then((response) => {
      setCompanies(response.data)
      setCompanyDescription(response.data.description);
     console.log(response.data);
     //setIsReloadService(false);
    }).catch(error =>{
      console.log(error);
    });
  },[isReloadService])

  const handleReplaceRepresentative = async()=>
  {
    setReplaceRepModalShow(false);
    CompanyService.getRepresentativeEndorsementDetails(companyId,replacedUserInfo.id);
    //alert("inside replace rep method")
    if(await confirm("Confirm Representative","You are about to replace  " +replacedUserInfo.name+"("+replacedUserInfo.email+")"+"  with  " 
    +newRepName+"("+newRepEmail+")." +"   "+newRepName+" will take over all endorsements and invitees made by" +replacedUserInfo.name+"("+replacedUserInfo.email+")")) {
      setOtpInputModelOpenendForAction("replaceRep");
      setReplaceRepModalShow(false);
      await getXCode('REPLACE_REP', 'PATCH', companyId,replacedUserInfo.id);
    }
    else{

    }
  }



 function ReplaceRepresentativeModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      backdrop='static' 
      keyboard="False"
      dialogClassName='replaceRepModal'
     


    >
      <Modal.Header className='modal-header'>
        <Modal.Title id="contained-modal-title-vcenter">
        Replace Representative
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body'>
      <div className="apim-pop-content">
<ul className="crep-wrap">
  <li className="current-crep">
    <div className="crep-label">
      <div className="custom-sel-label">
      {selectedNewRepresentativeId?<span className="aftersel-txt" style={{display:'-ms-inline-flexbox'}}>
Select Representative
</span>:<></>}
       
      <Form.Select
        defaultValue={"Select Representative"}
        name="item-selected"
        value={selectedNewRepresentativeId}
        onChange={handleChange}
          className="form-select apim-custom-replace-rep-dropdown"
          id="search-select-representative"
          
        >
          
          <option
                                  className="textdefault"
                                  selected
                                  value={0}
                                >
                                  {"Select Representative"}
                                </option>
          {companies.representatives &&
              companies.representatives.filter(e=>e.id!=replacedUserInfo.id).map((rep) => {
               
                return <option value={rep.id} title={rep.email}>{rep.name}</option>;
              })}
         
        </Form.Select>
      </div>
      <span className="sel-othertxt"> 
      to replace {replacedUserInfo.name}({replacedUserInfo.email}).
      </span>
    </div>
  </li>
</ul>
{/* <div className="apim-pop-button-w">
  <button className="ui button comm-button-style primary apim-pop-button-create mpa-button-confirm">
    Ok
  </button>
  <button className="ui button comm-button-style secondary apim-pop-button-cancel mpa-button-cancel {disableCancelClass}">
    Cancel
  </button>
</div> */}
</div>
      </Modal.Body>
      <Modal.Footer>
      <button className="ui button comm-button-style primary" disabled={!selectedNewRepresentativeId} onClick={()=> handleReplaceRepresentative()}>OK</button>
      <button className="ui button comm-button-style primary" onClick={() => {

            setReplaceRepModalShow(false);
            setSelectedNewRepresentativeId(0);
          }}>CANCEL</button>
      </Modal.Footer>
    </Modal>
  );
}
const replaceRepresentative = (currentRepDetails,isConfirmedRep) =>
{
console.log("you clicked on replace representative");
setReplacedUserInfo({id:currentRepDetails.id,name:currentRepDetails.name,email:currentRepDetails.email})
if(isConfirmedRep===true)
{
  setConfirmedRepId(currentRepDetails.id);
  navigateToServiceRequestPage(currentRepDetails.id);
}
else{
  setReplaceRepModalShow(true);
}
}


  async function verifyOtp(OtpDataFromModal) {

    if (OtpDataFromModal.length != 0) {

      console.log(OtpDataFromModal)
      OtpDataFromModal = Number(OtpDataFromModal.join(''));

      if(otpInputModelOpenendForAction==="inviteRep")
      {
        let request={"email":invitedRepEmail}
        ProjectsService.inviteRepresentative(companyId, request, OtpDataFromModal).then((response) => {
        
    
         toast.success('Invitation successfully sent to '+response.data.email, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 8000, //6 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Slide
        });
        setOtpModalOpen(false);
        setIsReloadService(isReloadService+1);
        console.log(response.data);
        
          
        }).catch(error =>{
          console.log(error);
          toast.error(error.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 8000, //6 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            transition: Slide
          });
        });
  
  
  
      }
      else if (otpInputModelOpenendForAction==="replaceRep")
        {
          
          let request={"newRepresentativeId":selectedNewRepresentativeId}
         CompanyService.replaceRepresentative(companyId, request, replacedUserInfo.id, OtpDataFromModal).then((response) => {
          if(response)
          {
            toast.success(response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 8000, //6 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              transition: Slide
            });
            setTimeout(() => { 
              window.location.reload();
          }, 4000); 
          
          }
    
      
        setOtpModalOpen(false);
       
        console.log(response.data);
        
          
        }).catch(error =>{
          console.log(error);
          toast.error(error.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 8000, //6 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            transition: Slide
          });
        });
        }
      }
   

  }

  const handleOtp = (OtpDataToProject) => {
    setOtpDataFromModal(OtpDataToProject);
    //alert(OtpDataToProject);
  }
  const handleChange = (e) => {
    setSelectedNewRepresentativeId(e.target.value);
    const value = e.target.value;
    setNewRepEmail(companies.representatives.filter(e=>e.id==value).map(user => {
      return user.email;
    }))
    setNewRepName(companies.representatives.filter(e=>e.id==value).map(user => {
      return user.name;
    }))
    
    
    
  //     console.log("new rep data"+companies.representatives.filter(e=>e.id==value).map(user => {
  //       return user.name;
  //     }))
  //  // setSelectedNewRep({id:value,name:newRep.name,email:newRep.email});
  //   console.log("-------------------" + e.target.value);
  };
  
  console.log("selected new rep name"+newRepName)
  console.log("selected new rep mail"+newRepEmail)
  const inviteRepresentative = () => {
    setModalOpen(true);
    }
    const handleInviteRepresentative = async(repEmail) => {
      setInvitedRepEmail(repEmail);
      setOtpInputModelOpenendForAction("inviteRep");
      await getXCode('INVITE_REP', 'POST', companyId);
    }

    const resendOtp = async() => {
      if(otpInputModelOpenendForAction==="inviteRep")
      {await getXCode('INVITE_REP', 'POST', companyId);}
      else if(otpInputModelOpenendForAction==="replaceRep")
      {await getXCode('REPLACE_REP', 'PATCH', companyId,replacedUserInfo.id);}
    }
  
  const undoCompanyDescription = () =>{
    setCompanyDescription(companies.description);
    setIsEditDescription(false);
  }
  const updateCompanyDescription = async() =>{
    const confirmationCode = await getXCode('EDIT_COM_DESCRIPTION', 'PUT', companyId);
    let request={"description":companyDescription}
    ProjectsService.editCompanyDescription(companyId, request, confirmationCode).then((response) => {
     // setCompanies(response.data)
     // setCompanyDescription(response.data.description);
      setIsEditDescription(false);
     console.log(response.data);

     toast.success(response.data.message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 8000, //6 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      transition: Slide
    });
    setIsReloadService(isReloadService+1);
    
      
    }).catch(error =>{
      console.log(error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000, //6 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide
      });
    });
  }

  const getXCode = async (action, httpVerbData, ...param) => {
    const response = await ProjectsService.getXStrongConfirmationCode(action, httpVerbData, ...param);
    const code = response.data.code;
    console.log("confirmation code:" + code);
    if (response && response.data.channel === "NO_CHECK") { return code; }
    else if (response && response.data.channel === "SMS") { 
      
      setOtpModalOpen(true);
      setModalOpen(false);
      return code; }
    else {
      let errorMessage = 'unhandled X-code channel exception';
      alert(errorMessage);
      throw new Error(errorMessage);
    }
  }

  return (
    <div className="container-flude mt-5 editCompany" style={{minHeight: '250px', gutterX: 0}}>
        <h2 className="ms-5 mb-5"><b style={{fontSize:'35px'}}>EDIT COMPANY</b></h2>
<div style={{backgroundColor:'white'}} className="container-flude pb-5">
       <div className="container">
       <a href="/projects" className="pt-4 row" target="_self">
       
        <div className='col-1' style={{width:'3%',height:'2%'}}>
            <h2 className="bi bi-arrow-left-short mb-2"></h2>
            <td></td>
        </div>
        <div className='col-8'><h2 className="page-title">{companies.name}</h2> </div>
        </a>
       

    <div className="accordion" id="accordionExample">
    <div className="accordion-item pt-3">
        <div className="accordion-header" id="headingOne">
          <div className="row m-0" style={{borderBottom: '1px solid #2d32aa'}}>
            <div className="col-10 d-flex justify-content-left text-center " style={{paddingLeft:'0px'}}> 
            <span style={{color: '#2d32aa', fontWeight: 'bold'}} className=""><h5>{companies.registrationCode}</h5></span> 
            </div>
            
            <div className="col-2">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne1" style={{marginLeft: '25px'}}>
              </button>


             

            </div>
          </div>    
          <div id="collapseOne1" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="">
            <div className="accordion-body" style={{paddingLeft:'0px', paddingRight:'0px'}}>
             
        
                            <div className="com-custom-input-wrapp mt-3">
                              <textarea 
                              id="textAreaModifyDescription" 
                              className="com-cust-text-a" 
                              maxlength="4000" 
                              aria-multiline="true" 
                              tabindex="1" 
                              value={companyDescription}
                              onChange={(e) => setCompanyDescription(e.target.value)}
                              disabled={!isEditDescription}
                              >
                                </textarea>
                                <div className="com-right-actions" style={{display: 'block'}}>
                                  
                                    {!isEditDescription?<>
                                      <a className="me-1" target="_blank" onClick={() => setIsEditDescription(true)}>
                                    <span className="bi bi-pencil editIcon "></span>
                                    </a>
                                    </>:<>
                                    <a className="me-2" target="_blank" onClick={updateCompanyDescription}>
                                    <span className="bi bi-check-square"></span>
                                    </a>
                                    <a className="" target="_blank" onClick={undoCompanyDescription}>
                                    <span >X</span>
                                    </a>
                                    </>}
                                    </div>
                            </div>



            </div>
          </div>
        </div>
      </div>

     
      <div className="accordion-item pt-3">
    <div className="accordion-header" id="headingOne">
        <div className="row m-0" style={{borderBottom: '1px solid #2d32aa'}}>
            <div className="col-10 d-flex justify-content-left text-center" style={{paddingLeft: '0px'}}>
                <span style={{color: '#2d32aa', fontWeight: 'bold', fontSize: '21px'}} className=""><h5>REPRESENTATIVES</h5></span> 
            </div>
            <div className="col-1 d-flex justify-content-end mt-1 pe-0 inviteRepresentativeButtonDiv">
                <button type="button" className="openModalBtn inviteRepresentativeButton pe-0" onClick={() => inviteRepresentative()} data-bs-toggle="tooltip" data-bs-placement="top" title="Invite New Representative" style={{border: 'none', backgroundColor: 'white'}}>
                    <h1 style={{color: '#2d32aa'}} width={40} height={40}>+</h1>
                </button>
            </div>
            <div className="col-1 d-flex justify-content-end text-center inviteRepresentativeAccBtnDiv">
                <button className="accordion-button inviteRepresentativeAccBtn collapsed pe-0 pl-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                </button>
            </div>
        </div>
        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="">
            <div className="accordion-body" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                <table className="table c-blue">
                    <thead>
                        <tr className="">
                            <td>NAME</td>
                            <td>EMAIL</td>
                            <td>ENDORSED BY</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody style={{backgroundColor: '#c9e9fb'}}>
                        {companies.representatives && companies.representatives
                            .map((record) => (
                                <tr style={{borderBottom: '2px solid #fff'}}>
                                    <td className="pt-3">{record.name}
                                        {record.isConfirmed ? <i className="bi bi-person ms-1"></i> : <></>}
                                    </td>
                                    <td className="pt-3">{record.email}</td>
                                    <td className="pt-3">{record.endorsedBy}</td>
                                    <td className="pt-3">
                                        {companies.userCanRemoveDelegate ? 
                                            <a href="#" className="text-center" onClick={() => replaceRepresentative(record, record.isConfirmed)}>
                                                <h5 className="bi bi-shuffle"></h5>
                                            </a>
                                            : <></>}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className="mt-3 text-nowrap c-blue">
                    <i className="bi bi-person"></i>
                    <span className="ms-2">- Confirmed Representative of the Company</span>
                </div>
            </div>
        </div>
    </div>
</div>






    </div>
    </div>
    </div>
    <ReplaceRepresentativeModal
        show={replaceRepModalShow}
        onHide={() => setReplaceRepModalShow(false)}
      />
    <div className="toast-container"><ToastContainer limit={5}/></div>
    {modalOpen &&<InviteRepresentative setOpenModal={setModalOpen} setOpenOtpModal={setOtpModalOpen} companyID={companyId} handleInviteRepresentative={handleInviteRepresentative}/>}
    {OtpModalOpen && <OtpinputModal ModalHeading={''} setOpenOtpModal={setOtpModalOpen} handleOtp={handleOtp} value={OtpDataFromModal} verifyOtp={verifyOtp} credentialRecord={''} otpInputModelOpenendForAction={otpInputModelOpenendForAction} resendOtp={resendOtp} />}

  </div>
  
    )
  }
export default EditCompany;