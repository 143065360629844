import React from 'react'
import Modal from 'react-bootstrap/Modal';
import {useNavigate} from 'react-router-dom';
import CloseButton from 'react-bootstrap/CloseButton';
import {useState, useEffect} from 'react'
import { Button } from 'react-bootstrap'
import { useParams} from "react-router-dom";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './MembersModal.css';

import ProjectsService from '../../Services/ProjectsService';
import './ModalDialog.css';
// import ToasterComponent from '../../Assets/Utils/ToasterComponent';
import { ToastContainer, toast ,Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//toast.configure();
function ModalDialog({setOpenModal,ModalHeading,companyID,companyName}) {
  const [showModal, setShow] = useState({setOpenModal}); 
  const handleClose = () => setShow(false); 
  const handleShow = () => setShow(true);
  const [companyId, setCompanyId] = useState({companyID});
 // const {companyId,projectId} = useParams();
  console.log("companyId in modal dialog is====>"+companyID)
  console.log("companyname in modal dialog is====>"+companyName)
  const [projectName, setProjectName] = useState('');
  const [projectId, setProjectId] = useState('');
  const [companyNameAddProject, setCompanyNameAddProject] = useState(' ');
  const [clickedOnCreateProjectButton, setClickedOnCreateProjectButton] = useState(false);
  const [projectNameEmpty, setProjectNameEmpty] = useState(true);

 const defaultCompanyName = "Sandbox";

 const navigate = useNavigate();
  const navigateToProjectDetailsPage = () => {
    // 👇️ navigate to /
    navigate('/projects/:'+{companyID}+'/:'+{projectId});
  };
 
   console.log("project name:------"+projectName) 
   //setCompanyId(companyID);
  
   

   useEffect(() => {
  
    if(companyName!==null)
    {
      setCompanyNameAddProject(companyName);
     console.log("project type ===>"+{companyName})
    }
    else
    {
      setCompanyNameAddProject(defaultCompanyName);
     //setProjectType("sandbox")
     //console.log("project type ===>"+{projectType})
    }
    
      
    },[])
   
    
    
 
   
  const handleSubmit = async () => {
    if(companyID!==null)
    {
      //alert("hahahaha wait")
      try {
        const response = await ProjectsService.addNewTstProject(projectName,companyID);
        if(response)
        {
         //alert("response on proj creation =="+response.data.id)
         setProjectId(response.data.id);
         
          toast.success("Project Created Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 8000, //6 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            transition: Slide
          });
          console.log("test project created with name="+projectName);
          
          console.log("create project response-->"+response.data);
         
          
        }
        //alert("project id set =="+projectId);
        let URL = "/projects/".concat(companyID).concat("/").concat(response.data.id);
        setTimeout(() => { 
          window.location.replace(URL);
      }, 4000); 
        
        console.log(response.data);
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 6000, //6 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Slide
        });
        
      }

    }
    //setProjectTypeForAddProject();
    //alert("inside handle submit email/projectid"+email,projectId)
    
else if(companyID===null){
    try {

     // const response = await ProjectsService.addNewTstOrSbxProject(projectType,companyId,projectName);
     const response = await ProjectsService.addNewSbxProject(projectName);
      if(response)
      {
        //alert("response on proj creation =="+response.data.id);
        setProjectId(response.data.id);
        //alert("project id set =="+projectId);
        toast.success("Project Created Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 6000, //6 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Slide
        });
        console.log("sandbox project created with name="+projectName);
        console.log(response.data)
       
        //window.location.reload();
      }
      //alert("project id set =="+projectId);
        let URL = "/projects/".concat(response.data.id);
        setTimeout(() => { 
          window.location.replace(URL);
      }, 4000); 
      console.log(response.data);

    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 6000, //6 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide
      });

      console.error(error);
      console.log(error.response.data.message)

    }
  }

  }
  return(
   
    
    <Modal
      //{...props}
      animation={false}
      show={showModal}
      onHide={handleClose}
      size='lg'
      aria-labelledby="contained-modal-title-vcenter" 
      centered
      backdrop='static' 
       keyboard="False"
       scrollable='false'
       
       
    >
      <Modal.Header className='apim-pop-header'>
        <Modal.Title id="contained-modal-title-vcenter">
         New Project In {companyNameAddProject}
        </Modal.Title>
        {/* <div class="rightSec-head"> */}
        <a href="javascript:;" class="pop-close-wrap mpa-button-cancel" onClick={() => {
               setOpenModal(false);
             }}>
        <span class="bi bi-x-lg"></span></a>
           
        {/* </div> */}
      </Modal.Header>
      <Modal.Body>
      <div className="custom-input-wrapp has-validation">
      <FloatingLabel
        controlId="projectNameInput"
        label="Enter Project Name"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setProjectName(event.target.value)}
      >
        <Form.Control type="projectName" placeholder="name@example.com" />
         {/* <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Email Id is invalid</span> */}
      </FloatingLabel>
      </div>
          
         
      </Modal.Body>
      <Modal.Footer>
      <button className="ui button comm-button-style primary apim-pop-button-create" id="createNewProjButton"
        onClick={() => {
          handleSubmit();
          setClickedOnCreateProjectButton(true);
        }} disabled={projectName.length===0} > 
        {setClickedOnCreateProjectButton===true?"Creating..." :"Create"}
        </button>
        <button className="ui button comm-button-style secondary apim-pop-button-cancel" id="closeButton"
       onClick={() => {
        setOpenModal(false);
      }}  disabled={projectName.length===0} > 
        Cancel
        </button>
  
        <div className="toast-container"><ToastContainer limit={2}/></div>
      </Modal.Footer>
    </Modal>
  
  );
  
      
}
export default ModalDialog;