import React from 'react';

const Home = () => {

    return (
      <div className="container  overflow-auto" style={{minHeight: '300px'}}>
        <p className="item mt-5" /><br />
        <i><h3>Get started with our services – choose a product and explore Nets developer site! </h3></i>
        <p />
      </div>
    );
  };
  
  export default Home;