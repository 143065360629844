import React, { useState} from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import "react-phone-input-2/lib/style.css";
import './AllTabs.css'
import UserService from '../../Services/UserService';
import { ToastContainer, toast ,Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ChangeDisplayName = () => {
  const [displayName, setDisplayName] = useState('')
  const [isUpdateButtonActive, setIsUpdateButtonActive] = useState(false);

  let userDetailsObject = {
    displayName: ''
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    userDetailsObject.displayName=displayName;
   
    console.log("userDetailsObject.displayName------>"+userDetailsObject.displayName)

    userDetailsObject= JSON.stringify(userDetailsObject);
    await UserService.changeUserDisplayName(userDetailsObject).then(async(response) => {
      await UserService.refereshUser();
      setIsUpdateButtonActive(false); 
      toast.success(response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000, //6 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide
      });
      //setModalShow(true);
     
      setTimeout(() => { 
        window.location.reload();
    }, 4000);
  }).catch(error =>{
      console.log(error);
      //setOtpModalOpen(true);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000, //6 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide
      });
  })
   
  }
  
  console.log("displayName ---"+displayName)
    return(
    <>
    
    <ul className="list-unstyled">
            <li>
              <h3>Change Display Name</h3>
            </li>
            <li>
                    <div className="custom-input-wrapp">
                    <FloatingLabel
        controlId="dNameJU"
        label="Enter New Display Name"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setDisplayName(event.target.value)}
      >
        <Form.Control type="name" placeholder="name@example.com" />
        <span className="input-info-label">Eg: John Smith</span>
        <span className="required-field-op">(Required)</span>
        <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Email Id is invalid</span>
      </FloatingLabel>
      
                    </div>
                  </li>
                  <li>
                <div className="apim-pop-button-w comm-button-wrap">
                  <button className="ui button comm-button-style primary apim-reset-pass-btn btn-disable" id="updateBtn"  onClick={handleSubmit}>Update</button>
                  <button className="ui button comm-button-style secondary custom-loader" id="loadingBtn" style={{display: 'none'}}>
                    <i className="icon-plus iconLoader" />
                    Changing DisplayName...
                  </button>
                  <button className="ui button comm-button-style secondary apim-pop-button-cancel" id="cancelBtn">Cancel</button>
                </div>
              </li>
          </ul>
          <div className="toast-container"><ToastContainer limit={4}/></div>
    </>
    )
}
export default ChangeDisplayName;