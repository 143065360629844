import {COMPANIES_API,PROJECTS_API} from '../Config/AppConfiguration';
import axios from 'axios';
import Cookies from 'universal-cookie';
class ActivityLogService{

    getAccesssToken(){ 
        const cookies = new Cookies();
        var BEARER_TOKEN = 'Bearer '.concat( cookies.get("accessToken")); 
        return BEARER_TOKEN;
    }
    getActivityLogsByCompanyId(companyId, requestData){
        const URL=COMPANIES_API.concat(companyId).concat('/activities/');
        return axios.get(URL, {
            params: requestData,
          })
        
    }

    getActivityLogsByProjectId(projectId, requestData){
        const URL=PROJECTS_API.concat(projectId).concat('/activities/');
        return axios.get(URL, {
            params: requestData,
          })
    }
   
    }
    export default new ActivityLogService();