import React, { useState, useEffect } from 'react';
import UserService from '../../Services/UserService';
import {REDIRECT_URL_PROJECT,REDIRECT_URL_CHPWD,REDIRECT_URL} from '../../Config/AppConfiguration';
import Cookies from 'universal-cookie';
import login from '../../Components/_Authentication/Login';
const HeaderAfterLogin = () =>  {
  const cookies = new Cookies();

const cookieNameUser = "userId";
const cookieNameToken = "accessToken";
const cookieNameCurrentUser = "currentAPIMUser";

const [userCookieAvilable, setUserCookieAvilable] = useState(false);
const [redirectURL, setRedirectURL] = useState(REDIRECT_URL_PROJECT);

   useEffect(() => {
    let accesstokenFromParameter = login.getParameterByName("access_token");
    let accessTokenExpiresInFromParameter = login.getParameterByName("expires_in");
    if(accesstokenFromParameter!==null && accesstokenFromParameter !=="")
    {
      cookies.set(cookieNameToken, accesstokenFromParameter, { path: '/'});
      console.log('OOOOOOOOOOOOOTOKEN-NewHeaderOOOOOOOOOOOOOOOO'+accesstokenFromParameter)
    }
    
     UserService.getuserDetails().then((response) => {
      var _user_object = login.setCurrentUserObject(response.data, accessTokenExpiresInFromParameter);
      let checkAccountStatus=response.data.accountStatus;
      if(checkAccountStatus==='ResetPW'||checkAccountStatus==='SignedUp')
      setRedirectURL(REDIRECT_URL_CHPWD);
    else if(checkAccountStatus==='Deleted')
    setRedirectURL(REDIRECT_URL);
      _user_object = encodeURI(JSON.stringify(_user_object));
        cookies.set(cookieNameCurrentUser,_user_object,{ path: '/'});
        cookies.set(cookieNameUser, response.data.email,{ path: '/'});
        setUserCookieAvilable(true);
       
      }
      )
      .catch(error =>{
        console.log(error.status);
      });
    },);

  return (
    <div style={{display : 'none'}}>
    {userCookieAvilable === false 
     ?<></>
     :(window.location.href = redirectURL)
     } 
    </div>
       );
      }

export default HeaderAfterLogin;
