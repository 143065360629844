import React from 'react'
import Modal from 'react-bootstrap/Modal';
import {useState} from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './ModalDialog.css';
import { ToastContainer, toast ,Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function InviteRepresentative({setOpenModal,setOpenOtpModal, companyID, handleInviteRepresentative}) {
  const [showModal, setShow] = useState({setOpenModal}); 
  const handleClose = () => setShow(false); 
  const handleShow = () => setShow(true);
  const [companyId, setCompanyId] = useState({companyID});
  console.log("companyId in modal dialog is====>"+companyID)
  const [repEmail, setRepEmail] = useState('');
  const [clickedOnInviteRepButton, setClickedOnInviteRepButton] = useState(false);
  const [message, setMessage] = useState(" ");
 
  const egEmailStyle={
      fontFamily: '"UniNeueRegular", sans-serif',
      fontWeight: '400',
      fontStyle: 'normal',
      fontSize: '15.1px'
  }

  function handleEmail(event) {
    let inputValue = event.target.value;
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (!emailRegex.test(inputValue)) {
      setMessage("Email Id is invalid");
    } else {
      setMessage("");
      setRepEmail(inputValue);
    }
  }

  return(
   <>
    <Modal
      //{...props}
      animation={false}
      show={showModal}
      onHide={handleClose}
      size='lg'
      aria-labelledby="contained-modal-title-vcenter" 
      centered
      backdrop='static' 
       keyboard="False"
       scrollable='false'
       
       
    >
      <Modal.Header className='apim-pop-header'>
        <Modal.Title id="contained-modal-title-vcenter">
        Invite New Representative
        </Modal.Title>
        {/* <div class="rightSec-head"> */}
        <a href="javascript:;" class="pop-close-wrap mpa-button-cancel" onClick={() => {
               setOpenModal(false);
             }}>
        <span class="bi bi-x-lg"></span></a>
           
        {/* </div> */}
      </Modal.Header>
      <Modal.Body>
      <div className="custom-input-wrapp has-validation">
      <FloatingLabel
        controlId="repEmail"
        label="Invite New Representative"
        className="inputText"
        autoComplete="off"
        onChange = {e => handleEmail(e)}
      >
        <Form.Control type="repEmail" placeholder="name@example.com" />
        <span style = {{ color: "red", fontSize: "12px"}}> {message} </span>
         {/* <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Email Id is invalid</span> */}
      </FloatingLabel>
      <span className="input-info-label" style={egEmailStyle}>Eg: username@domain.com</span>
      </div>
          
         
      </Modal.Body>
      <Modal.Footer>
      <button className="ui button comm-button-style primary apim-pop-button-create" id="inviteNewRepresentative"
        onClick={() => {
          handleInviteRepresentative(repEmail);
          setClickedOnInviteRepButton(true);
        }} disabled={repEmail.length===0} > 
        {clickedOnInviteRepButton===true?"Inviting..." :"Invite New Representative"}
        </button>
        <button className="ui button comm-button-style secondary apim-pop-button-cancel" id="closeButton"
       onClick={() => {
        setOpenModal(false);
      }}  disabled={repEmail.length===0} > 
        Cancel
        </button>
  
        <div className="toast-container"><ToastContainer limit={2}/></div>
      </Modal.Footer>
    </Modal>
    
    </>
  );
  
      
}
export default InviteRepresentative;