class AppCache{

async addDataIntoCache(cacheName, url, response) {
        const data = new Response(JSON.stringify(response));
        if ('caches' in window) {
          caches.open(cacheName).then((cache) => {
            cache.put(url, data);
            console.log('Current User Added into cache!')
          });
        }
      };

    async getSingleCacheData(cacheName, url) {
        console.log('getSingleCacheData..')
        if (typeof caches === 'undefined') return false;
        const cacheStorage = await caches.open(cacheName);
        const cachedResponse = await cacheStorage.match(url);
        // If no cache exists
        if (!cachedResponse || !cachedResponse.ok) {
          return 'Fetched failed!';
        }
        return cachedResponse.json().then((item) => {
          return item;
        });
      };

  
}
    export default new AppCache();