import React, { useState, useEffect } from 'react';
import { Outlet} from "react-router-dom";
import logo from '../../Assets/Images/nets-logo-white.png';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Cookies from 'universal-cookie';
import login from '../../Components/_Authentication/Login';
import {Popover} from 'react-bootstrap';
import Logout from '../../Components/_Authentication/Logout';
import { NETS_MAIN_WEBSITE_URL } from '../../Config/AppConfiguration';

const Header = () =>  {
  const cookieNameCurrentUser = "currentAPIMUser";
const iconStyle = {
    opacity: '1',
};
const cookies = new Cookies();
const [user, setUser] = useState('');
const [mainLogout, setMainLogout] = useState(false);
   useEffect(() => {
    if(login.currentUserTokenExpired()){
      setMainLogout(true);
    }else{
      var _user_attributes = cookies.get(cookieNameCurrentUser);
    if (_user_attributes) {
      _user_attributes = decodeURI(_user_attributes);
      const user_attributes = JSON.parse(_user_attributes);
      setUser(user_attributes);
    }
    }
    
    },[]);

    const popoverUrlStyle ={
      maxWidth: '22%',
      minHeight: '4%',
      fontSize: 'large',
      border: '1px solid #2d32aa',
      borderRadius: 3
    };

    const overlayStyle ={
      fontSize: '15px',
    fontWeight: 'normal',
    wordBreak: 'break-all',
    tableLayout:'fixed'
    };
    const overflowStyle ={
      textOverflow: 'ellipsis',
      whiteSpace:'nowrap',
      overflow: 'hidden'
    };
      const renderTooltip = (props) => (
        <Popover id={`popover-positioned-bottom`} style={popoverUrlStyle} >  
<Popover.Body className=""> 
<table className="table table-borderless m-0 p-0 " style={overlayStyle}>
<tr ><td style={overflowStyle} >{user.displayName}</td></tr>
<tr ><td style={overflowStyle} >{user.email}</td></tr>
</table>

</Popover.Body>  
</Popover>
      
      );
    

  return (
    <div className="container-fluid">
      <nav className="navbar navbar-expand-lg  navbar-dark bgblue" width="100%">
        {/* Container wrapper */}
        <div className="container-fluid">
          {/* Navbar brand */}
          <a className="navbar-brand ms-3" href="/">
            <img width={169} src={logo} alt="Nets Logotype" />
          </a>
          {/* Toggle button */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i className="navbar-toggler-icon" />
          </button>
          {/* Collapsible wrapper */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Left links */}
            <ul className="navbar-nav navbar-dark me-auto mb-2 mb-lg-0">
            </ul>
            {/* Left links */}
            <ul className="navbar-nav ms-3 ">
              <li className="nav-item item-center">
                <a className="nav-link" href={NETS_MAIN_WEBSITE_URL} target="_blank"><h6>GO TO NETS MAIN WEBSITE</h6></a>
              </li>
              <li className="nav-item item-center">
                <a className="nav-link" href="/projects"><h6>GO TO PROJECTS</h6></a><h6><a className="nav-link" href="#" />
                </h6></li>
                <li class="nav-item dropdown">
                <OverlayTrigger
                 placement="bottom" 
                 delay={{ show: 250}} 
                 overlay={renderTooltip()}
                  trigger="hover"
                  rootClose
                 
                 
                 >
      <a className="nav-link dropdown-toggle me-lg-3" variant="success" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 
                <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} viewBox="0 0 35 35" className="icon iconStyle">
                  <g id="Group_571" data-name="Group 571" transform="translate(-507.468 -391.705)">
                    <g id="user-o">
                      <path id="Path_249" data-name="Path 249" d="M524.653,401.648a2.149,2.149,0,1,1-2.148,2.15h0a2.148,2.148,0,0,1,2.147-2.149h0m0,9.21c3.04,0,6.56,1.492,6.56,2.147v3.087H518.1v-3.086c0-.653,3.509-2.147,6.548-2.147m0-11.154a4.093,4.093,0,1,0,4.094,4.092,4.093,4.093,0,0,0-4.094-4.092Zm0,9.209c-2.731,0-8.186,1.371-8.186,4.092v4.739h16.372v-4.739C532.839,410.285,527.385,408.914,524.653,408.914Z" fill="#ffffff" />
                    </g>
                    <g id="Ellipse_7" data-name="Ellipse 7">
                      <g id="Group_569" data-name="Group 569">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="17.5" cy="17.5" r="17.5" transform="translate(507.468 391.705)" fill="none" />
                      </g>
                      <g id="Group_570" data-name="Group 570">
                        <path id="Path_250" data-name="Path 250" d="M524.968,426.7a17.5,17.5,0,1,1,17.5-17.5A17.52,17.52,0,0,1,524.968,426.7Zm0-33a15.5,15.5,0,1,0,15.5,15.5A15.517,15.517,0,0,0,524.968,393.7Z" fill="#ffffff" />
                      </g>
                    </g>
                  </g>
                </svg>
              </a> 
</OverlayTrigger>               
  
<ul className="dropdown-menu dropdown-menu-end p-0 " aria-labelledby="navbarDropdown" style={{zIndex:9999, backgroundColor: '#fff'}}>  
                  <li><a className="dropdown-item" href="/userProfile">My Profile</a></li>
                  <li><a className="dropdown-item" href="/serviceRequest">Service Request</a></li>
                  <li><a className="dropdown-item" href="/activityLog">View Activities</a></li>
                  <li><a className="dropdown-item" href="/viewserviceRequests">View Service Requests</a></li>
                  <li><a className="dropdown-item" href="#" onClick={() => setMainLogout(true)} >Log Out</a></li>
                </ul>
        </li>
             
            </ul>
          </div>
          {/* Collapsible wrapper */}
        </div>
        {/* Container wrapper */}
      </nav>
      {/* Navbar */}
      
    <Outlet />
   <Logout logout={mainLogout}/>
    
    </div>
       );
      }

export default Header;
