import React, { useContext, useState, useEffect, useRef } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import './OtpinputModal.css';

export const OtpContext = React.createContext(null);

const padTime = time => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

const format = time => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}m ${padTime(seconds)}s`;
};

function OtpinputModal({ setOpenOtpModal, ModalHeading, handleOtp, value, verifyOtp, otpInputModelOpenendForAction, credentialRecord, newMobile, otpResendRequest, resendOtp }) {
  const [showModal, setShow] = useState(true);
  const handleClose = () => setShow(false);

  const otpRef = useRef('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [user, setUser] = useState('');
  const [isClearHighlighted, setClearHighlighted] = useState(false);
  const [isConfirmHighlighted, setConfirmHighlighted] = useState(false);
  const clearButtonRef = useRef(null);
  const confirmButtonRef = useRef(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [spinerActive, setSpinerActive] = useState(false);
  const cookieNameCurrentUser = "currentAPIMUser";

  const [counter, setCounter] = React.useState(300);

  useEffect(() => {
    otpRef.current = otp;
    value = otpRef;
    handleOtp(value);
    const cookies = new Cookies();
    if (cookies !== null) {
      var _user_attributes = cookies.get(cookieNameCurrentUser);
      if (_user_attributes) {
        _user_attributes = decodeURI(_user_attributes);
        const user_attributes = JSON.parse(_user_attributes);
        setUser(user_attributes);
      }
    }
  }, [otp]);

  React.useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(c => c - 1), 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        if (isConfirmHighlighted && confirmButtonRef.current) {
          confirmButtonRef.current.click();
        } else if (isClearHighlighted && clearButtonRef.current) {
          clearButtonRef.current.click();
        }
      }
    };

    if (showModal) {
      document.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [showModal, isClearHighlighted, isConfirmHighlighted]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    const newOtp = [...otp.map((d, idx) => (idx === index ? element.value : d))];
    setOtp(newOtp);

    // Highlight CLEAR button when the first digit is entered
    setClearHighlighted(newOtp.some(v => v !== ""));

    // Highlight CONFIRM button when all digits are entered
    if (newOtp.every(v => v !== "")) {
      setConfirmHighlighted(true);
    } else {
      setConfirmHighlighted(false);
    }

    // Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  return (
    <Modal
      animation={false}
      show={showModal}
      onHide={handleClose}
      dialogClassName="modal-70w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
      keyboard="False"
    >
      
      <Modal.Header className='apim-pop-header'>
        
        <div className="row">
        <div className="col-8">
        <Modal.Title id="contained-modal-title-vcenter col-6">
          SMS Confirmation
        </Modal.Title>
        </div>
        <div className="col-4 d-flex justify-content-center mt-2"> {counter === 0 ? "" : <h6>Code expires in : {format(counter)}</h6>}</div>
        </div>
        
        
        <div className="col-sm d-flex justify-content-end">
            <a href='javascript:;' className="pop-close-wrap mpa-button-cancel bi bi-x-lg"  onClick={() => setOpenOtpModal(false)}/>
        </div>
      </Modal.Header>
      
      <Modal.Body className='OtpBody'>
        <p className="popinfo mb-7">
          Please enter the authentication code we have sent you by SMS on {newMobile && newMobile !== "" ? newMobile : user.mobileNumber}.
        </p>
        <div className="otpContainer mb-0">
          <div>
            {otp.map((data, index) => (
              <input
                className="otp-field"
                type="text"
                name="otp"
                size="6"
                maxLength="1"
                key={index}
                value={data}
                onChange={e => handleChange(e.target, index)}
                onFocus={e => e.target.select()}
              />
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-container">
        <Button
          ref={clearButtonRef}
          disabled={btnDisabled}
          className={`comm-button-style-clear mr-2 ${isClearHighlighted && !isConfirmHighlighted ? "highlight" : ""}`}
          onClick={() => {
            setOtp([...otp.map(() => "")]);
            setClearHighlighted(false);
            setConfirmHighlighted(false);
          }}
        >
          CLEAR
        </Button>
        

        {counter <= 0 ? 
        
        <Button
          className={`comm-button-style-confirm highlight`}
          onClick={()=>{
            resendOtp();
            setCounter(300);
            setOtp([...otp.map(() => "")]);
            setClearHighlighted(false);
            setConfirmHighlighted(false);
          }}
        >
        RESEND CODE
        </Button>
        
        
        :
        
        
        <Button
          ref={confirmButtonRef}
          disabled={btnDisabled}
          className={`comm-button-style-confirm ${isConfirmHighlighted ? "highlight" : ""}`}
          onClick={async() => {
            setBtnDisabled(true);
            setSpinerActive(true);
            verifyOtp(otp)
           setTimeout(() => {
            setBtnDisabled(false);
            setSpinerActive(false);
          }, 7000);
            // setOpenOtpModal(false)
            
            
          }}
        >
          {!spinerActive?<>CONFIRM</>:
                            <div style={{ display: 'block'}}> 
                           <Spinner animation="border" variant="primary"  style={{ width: 15, height: 15 , marginRight: 5}} /> 
                           VERIFYING...
                          </div> 
                           } 
        </Button>}


        <Button
          className="comm-button-style-close"
          onClick={() => setOpenOtpModal(false)}
          id='closeButton'
        >
          CANCEL
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OtpinputModal;
