
 const Metadata = [{
        "requestType": "CreateCompany",
        "displayName": "Register Company",
        "whenToUse": "This request is for new and existing customers to register to get access to our test and production environments.",
        "requestSubTypes": [{
            "requestType": "Default",
            "displayName": "",
            "whenToUse": "",
            "promptForNewCompany": {
                "isOptional": false,
                "promptName": "Company Details",
                "promptDescription": "Please provide the basic information about your company."
            },
            "promptForNewCRep": {
                "isOptional": false,
                "promptName": "Representative",
                "promptDescription": "Please provide details for the person who will be accountable for managing the company's access to Nets' production systems (the 'representative'). The representative can use the self-service portal to delegate responsibilities."
            },
            "promptForText": {
                "isOptional": true,
                "promptName": "Additional details",
                "promptDescription": "Please provide any additional information relevant to the registration process here."
            }
        }]
    },
    {
        "requestType": "ManageCompany",
        "displayName": "Manage Company Records",
        "whenToUse": "This request is for modifying the records for an existing company, such as appointing a new accountable representative or changing the name or registration code of the company.",
        "requestSubTypes": [{
                "requestType": "ModifyCompany",
                "displayName": "Modify Registration Details",
                "whenToUse": "This request is for modifying the company details (name, country or registration code)",
                "promptForExistingCompany": {
                    "isOptional": false,
                    "promptName": "Company to modify",
                    "promptDescription": "Please select the company to update."
                },
                "promptForNewCompany": {
                    "isOptional": false,
                    "promptName": "New company details",
                    "promptDescription": "Please provide updated company details."
                },
                "promptForText": {
                    "isOptional": false,
                    "promptName": "Additional details",
                    "promptDescription": "Please provide reason for change and any additional information relevant to the registration process here."
                }
            },
            {
                "requestType": "AddCRep",
                "displayName": "Add new accountable representative.",
                "whenToUse": "This request is to add an extra accountable representative for your company.",
                "promptForExistingCompany": {
                    "isOptional": false,
                    "promptName": "Company",
                    "promptDescription": "Please select the company to be represented."
                },
                "promptForNewCRep": {
                    "isOptional": false,
                    "promptName": "Representative",
                    "promptDescription": "Please provide details for the extra person to register as accountable for managing the company's access to Nets' production systems (the 'representative'). Note: The new representative will be subject to a validation process. If you just wish to delegate responsibilities you can use the self-service portal to do so by editing the company details and inviting a new delegate."
                },
                "promptForText": {
                    "isOptional": true,
                    "promptName": "Additional details",
                    "promptDescription": "Please provide any additional information relevant to the registration process here."
                }
 
            },
            {
                "requestType": "ReplaceCRep",
                "displayName": "Replace an accountable representative",
                "whenToUse": "This request is used to permanently remove an existing accountable representative from the records and to let a new representative take the role. If there is no replacement available you can select that a replacement has not yet been identified.",
                "promptForExistingCompany": {
                    "isOptional": false,
                    "promptName": "Company",
                    "promptDescription": "Please select the relevant company."
                },
                "promptForExistingCRep": {
                    "isOptional": false,
                    "promptName": "Representative to be removed",
                    "promptDescription": "Select the representative to be removed. Only accountable representatives are available in the list. If you wish to replace a delegate you can use the self-service portal to do so by editing the company details and replacing the delegate directly from the list of delegates."
                },
                "promptForNewCRep": {
                    "isOptional": false,
                    "promptName": "Representative to take over",
                    "promptDescription": "Please enter the details of the user to take over responsibilities from the existing representative."
                },
                "promptForText": {
                    "isOptional": true,
                    "promptName": "Additional details",
                    "promptDescription": "Please provide any additional information relevant to the change and to the registration process of the new user here."
                }
            },
            {
                "requestType": "DeleteCompany",
                "displayName": "Delete Company Records",
                "whenToUse": "This request is to permanently remove your company records. Removing the company records will also terminate any API access granted to the company",
                "promptForExistingCompany": {
                    "isOptional": false,
                    "promptName": "Company",
                    "promptDescription": "Please select the company to remove."
                },
                "promptForText": {
                    "isOptional": true,
                    "promptName": "Additional details",
                    "promptDescription": "Please provide reason and any additional details relevant to the removal."
                }
            }
        ]
    },
    {
        "requestType": "APIVersionException",
        "displayName": "Access older API Version",
        "whenToUse": "This request is to configure a project to use an older version of an API that has been retired from self-service.",
        "requestSubTypes": [{
            "requestType": "Default",
            "displayName": "API Version Exception",
            "whenToUse": "",
            "promptForExistingCompany": {
                "isOptional": false,
                "promptName": "Company",
                "promptDescription": "Please enter the company this request relates to."
            },
            "promptForExistingProject": {
                "isOptional": false,
                "promptName": "Project",
                "promptDescription": "Select the project that will use the retired API"
            },
            "promptForText": {
                "isOptional": false,
                "promptName": "Additional details",
                "promptDescription": "Please enter name and version for each API you need added to the project. As we always encourage use of the latest available version, please indicate why you need access to the older version(s)."
            }
 
        }]
    },
    {
        "requestType": "TechnicalAssistance",
        "displayName": "Request technical assistance",
        "whenToUse": "This request is to get technical assistance with the use of APIs.",
        "requestSubTypes": [{
            "requestType": "Default",
            "displayName": "Technical Assistance",
            "whenToUse": "",
            "promptForExistingCompany": {
                "isOptional": false,
                "promptName": "Company",
                "promptDescription": "Please enter the company this request relates to."
            },
            "promptForExistingProject": {
                "isOptional": false,
                "promptName": "Project",
                "promptDescription": "Please enter the project where you need assistance."
            },
            "promptForText": {
                "isOptional": false,
                "promptName": "Additional details",
                "promptDescription": "Please provide additional details about why you need assistance."
            }
        }]
    },
    {
        "requestType": "ReportIssue",
        "displayName": "Report Issue",
        "whenToUse": "This request is to report observed defects in the APIs you use",
        "requestSubTypes": [{
            "requestType": "Default",
            "displayName": "Report Issue",
            "whenToUse": "",
            "promptForExistingCompany": {
                "isOptional": false,
                "promptName": "Company",
                "promptDescription": "Please enter the company this request relates to."
            },
            "promptForExistingProject": {
                "isOptional": true,
                "promptName": "Project",
                "promptDescription": "If applicable, please enter the project where you observed the issue."
            },
            "promptForText": {
                "isOptional": false,
                "promptName": "Additional details",
                "promptDescription": "Please provide the details of the issue you have observed and if possible, steps to reproduce."
            }
        }]
    },
    {
        "requestType": "ContactSales",
        "displayName": "Contact Sales",
        "whenToUse": "The request is for any enquiry related to the commercial use of APIs, such becoming a customer, getting access to APIs, terms and conditions etc.",
        "requestSubTypes": [{
            "requestType": "Default",
            "displayName": "Contact Sales",
            "whenToUse": "",
            "promptForExistingCompany": {
                "isOptional": true,
                "promptName": "Company",
                "promptDescription": "If applicable, please enter the company this request relates to."
            },
            "promptForText": {
                "isOptional": false,
                "promptName": "Additional details",
                "promptDescription": "Please provide the details of your enquiry."
            }
        }]
    },
    {
        "requestType": "Other",
        "displayName": "Other Enquiries",
        "whenToUse": "The request is for any enquiry that is not covered by other available request types.",
        "requestSubTypes": [{
            "requestType": "Default",
            "displayName": "Other Enquiries",
            "whenToUse": "",
            "promptForExistingCompany": {
                "isOptional": true,
                "promptName": "Company",
                "promptDescription": "If applicable, please enter the company this request relates to."
            },
			"promptForExistingCRep": {
                "isOptional": true,
                "promptName": "Representative",
                "promptDescription": "If applicable, please select the representative this request relates to."
            },
            "promptForExistingProject": {
                "isOptional": true,
                "promptName": "Project",
                "promptDescription": "If applicable, please enter the project this request relates to."
            },
            "promptForText": {
                "isOptional": false,
                "promptName": "Additional details",
                "promptDescription": "Please provide the details of your enquiry"
            }
        }]
    }
];

export default Metadata;