import React from 'react';
import fLogo from '../../Assets/Images/nets-logo-colored.png';
import '../../App.css';
import { FOOTER_NETS_EU_HREF_URL, FOOTER_PRIVACY_POLICY_HREF_URL, FOOTER_GENERAL_TC_HREF_URL } from '../../Config/AppConfiguration';

const fntColor = {
    color: '#2d32aa'
};


const Footer = () => {
    return (
  
        <div className="container-fluid bg-light footer">
          <footer className="container py-3 my-4" style={{fontSize: '13px'}}>
            <div className="row align-items-left py-3">
              <a href="/" data-to="/">
                <img width={169} src={fLogo} alt="Nets Logotype" />
              </a>
            </div>
            <div className="row justify-content-center pt-3 bg-light">
              <div className="col-sm pb-3"><a className="text-decoration-none float-lg-start fntColor" href={FOOTER_NETS_EU_HREF_URL} target="_blank">Nets EU</a></div>
              <div className="col-sm pb-3 me-lg-5"><a className="text-decoration-none fntColor" href={FOOTER_PRIVACY_POLICY_HREF_URL} target="_blank">Privacy policy</a></div>
              <div className="col-sm pb-3 me-lg-5"><a className="text-decoration-none fntColor" href={FOOTER_GENERAL_TC_HREF_URL} target="_blank">General Terms and Conditions</a></div>
              <div className="col-sm pb-3 ms-lg-5"><a className="text-decoration-none fntColor" href="/status">System status</a></div>
              <div className="col-sm pb-3"><a className="text-decoration-none ms-lg-5 float-lg-end fntColor" href="/.auth/login/aad">Nexi user login</a></div>
              <div style={{borderTop: '1px solid #607090!important'}}>
                <p className="text-muted justify-content-start float-lg-start mt-4 ml-0 mb-5">Copyright © 2023 Nets Denmark A/S. All rights reserved.</p>
              </div>
            </div>
          </footer>
        </div>
      );
    };
    
    export default Footer;