
import React, { useState, useEffect } from 'react';
import UserService from "../../Services/UserService";
const Notification = () => {

  const [notification, setNotification] = useState();
  const [isEnabled, setIsEnabled] = useState(false);
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [refresh, setRefresh] = useState(false);
   useEffect(() => {
    UserService.getUserProfileNotification().then((response) => {
      if(0!=response.data.length){
      setNotification(response.data);
      setIsEnabled( response.data && response.data[0].value === 'Yes' ? true : false);
       console.log(response.data);
      }else{
        let data=[{name:'CustomerNotifications-24hOpSummary',value:'No'}];
        setNotification(data);
      setIsEnabled( data && data[0].value === 'Yes' ? true : false);
      }
     })
     .then((data)=>{setRefresh(false);})
     .catch(error =>{
       console.log(error);
     });
   },[refresh, setRefresh])
   const handleChange = (e) => {
    setIsEnabled(e.target.checked);
    if(e.target.checked !== (notification[0].value === 'Yes' ? true : false)){
    setEnableUpdate(true);
    }else{
      setEnableUpdate(false);
    }
    }
    const handleUpdate = () => {
      let req = (isEnabled ? 'Yes' : 'No');
      UserService.enableNotification(notification[0].name,{value:req}).then((response) => {
         console.log(response.data);
       })
       .then((data)=>{setRefresh(true);})
       .catch(error =>{
         console.log(error);
       });
       setEnableUpdate(false);
    }
    return(
        <>
        <ul className="list-unstyled">
            <li>
              <div className="form-check form-switch ms-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inputNotificationSummary"
                 // defaultChecked={notification[0].value === 'Yes' ? true : false}
                  checked={isEnabled} 
                  style={{ fontSize: 25 }}
                  onChange={handleChange}
                />
                <label
                  className="form-check-label mt-1"
                  htmlFor="inputNotificationSummary"
                >
                  Subscribe to 24h operational summary
                </label>
              </div>
            </li>
            <li>
              <div className="d-flex align-items-center justify-content-end mt-5 me-5">
                <button
                  className={ !enableUpdate ? "comm-button-style disabledbtn me-5":"comm-button-style primary me-5" }
                  id="btnUpdNotification"
                  disabled={ !enableUpdate }
                  onClick={() => handleUpdate()}
                >
                  Update
                </button>
              </div>
            </li>
          </ul>
        </>
    )
}
export default Notification;