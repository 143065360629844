import React from 'react'
import Modal from 'react-bootstrap/Modal';
import {useState, useEffect} from 'react'
import { Button, Popover, OverlayTrigger} from 'react-bootstrap'
import './ViewCredentialPopup.css';
import { Tooltip } from 'react-tooltip';
//import 'react-tooltip/dist/react-tooltip.css';


function ViewCredentialPopup({setViewCredentialPopup,ModalHeading,popupFor,credentialData}) {
  //const [showModal, setShow] = useState({setViewCredentialPopup}); 
  const handleClose = () => setViewCredentialPopup(false); 
  const [copySuccess, setCopySuccess] = useState('');
  

  const popover = (data) =>(  
  
    <Popover id="popover-basic-viewcred" style={{width:'150px',maxHeight:'40px',backgroundColor:'#2d32aa',border:'1px solid #2d32aa'}}>    
      <Popover.Body className='row' style={{marginLeft:'2px',marginTop:'1px',color:'white',fontSize:'16px'}}>  
       Copy Credential
      </Popover.Body>  
    </Popover> 
    
  ); 
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard:', text);
    } catch (error) {
      console.log('Error copying to clipboard:', error);
    }
  };
  return(
   
    <div className="modalBackground" style={{opacity:'1'}}>
    <Modal
      //{...props}
      animation={false}
      show={true}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter" 
      centered
      scrollable='false'
      backdrop='static' 
      //dialogClassName='viewCredModal'
      contentClassName='viewCredPopUp'
       keyboard="False"

       
    >
      <Modal.Header className='apim-pop-header'>
        <Modal.Title id="contained-modal-title-vcenter">
        {ModalHeading}
        </Modal.Title>
        <div class="rightSec-head">
        <a href="javascript:;" class="pop-close-wrap mpa-button-cancel" onClick={() => {
               setViewCredentialPopup(false);
             }}>
        <span class="bi bi-x-lg"></span></a>
        </div>
      </Modal.Header>
      <Modal.Body className='viewCredBody'>

          <div className="input-group  custom-input-wrapp has-validation mt-1 mb-0">
        <div className="form-floating">
        <ul className="view-c-wrap view-copy-credentials mb-0">
  <li>
    <ul className="view-c-content">
      <li className="key-txt">Key:</li>
      <li className="key-secret">Secret:</li>
    </ul>
  </li>
  <li>
    <ul className="view-c-content">
      <li>
        <span className="h-cred-key" id="copy-cred-key">
          {credentialData.key}
        </span>
        <span style={{marginLeft:'4px'}}>
        <OverlayTrigger trigger="hover" placement="top"  overlay={popover()} style={{pointerEvents:'none'}}>
        <a
         className="bi bi-clipboard"
          href="javascript:;"
         onClick={()=>copyToClipboard(credentialData.key)}
        >
       
          <span className="icon-copy-two" />
        </a>
        </OverlayTrigger> 
        </span>
      </li>
      <li>
        <span className="h-cred-secret" id="copy-cred-secret">
          {credentialData.secret}
        </span>
        <span style={{marginLeft:'4px'}}>
        <OverlayTrigger trigger="hover" placement="top" overlay={popover()} style={{pointerEvents:'none'}}>
        <a
        
          className="bi bi-clipboard"
          href="javascript:;"
         onClick={()=>copyToClipboard(credentialData.secret)}
         
        >
        <span className="icon-copy-two" />
        </a>
        </OverlayTrigger>
        </span>
      </li>
      
    </ul>
  </li>
</ul>

        </div>
       
      </div>
      <button
            style={{float:'right'}}
            className="button comm-button-style primary btn-disable mt-2"
            id="btnSubmitViewCredential"
            tabIndex={1}
            onClick={
              handleClose
             }
           
          >
            OK
          </button>
          
         
      </Modal.Body>
      {/* <Modal.Footer>
      <button
            className="button comm-button-style primary btn-disable"
            id="btnSubmitViewCredential"
            tabIndex={1}
            onClick={
              handleClose
             }
           
          >
            Okay
          </button>
      </Modal.Footer> */}
    </Modal>
    </div>
  );
  
      
}
export default ViewCredentialPopup;