
import React, { useState, useEffect} from 'react'; 
import DataTable from "react-data-table-component";
import "./styles.css";
import moment from 'moment';
import SRQService from '../../Services/SRQService';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {Popover} from 'react-bootstrap'
const ViewServiceRequest = () => {
   
    const customStyles = {
        header: {
            style: {
                minHeight: '100px',
                color: '#2d32aa',
                borderRadius: 0,
                borderLeft: 'none',
                borderRight: 'none',
                background: '#f6f7f9',
                fontSize: '32px',
                textAlign: 'left',
                fontWeight: '600px',
            },
        },
        headRow: {
            style: {
                background: '#2d32aa',
                color: '#fff',
                padding: '14px ​12px',
                borderRadius: 0,
                fontWeight: 'normal',
                borderTop: '1.5px solid #3e6a85',
                borderBottom: '1.5px solid #3e6a85',
                borderLeft: 'none',
                minWidth: '120px',
                fontSize: '14.1px',
            },
        },
        headCells: {

            style: {
                '&:not(:last-of-type)': {
                },
                textAlign: 'left',
            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    //paddingBottom: '10px',
                    color: '#000',
                      textAlign: 'left',
                      padding: '14px ​12px',
                      opacity: '21',
                      fontSize: '13px',
                },
            },
        },
    };
    const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [erroressage, setErrorMessage] = useState('');
    useEffect(() => {
		fetchServiceRequests(); 
		
	}, []);

 const fetchServiceRequests = async() => {
        setLoading(true);
        await SRQService.getAllServiceRequests()
       .then((response) => {
        if(response.data.code==='APIM-7013'){setErrorMessage(response.data.message)}
        else
        setData(response.data);})
       .catch(error =>{setErrorMessage(error.message)})
        setLoading(false);
    };

       return <div className="App">
        {
          erroressage && erroressage!=''?
          <>
          <DataTable
          title="VIEW SERVICE REQUESTS"
          customStyles={customStyles}
          noDataComponent={erroressage}
        ></DataTable>
          </>
          :
          <DataTable
          title="VIEW SERVICE REQUESTS"
          columns={columns}
          data={data}
          paginationComponent={BootyPagination}
          highlightOnHover={true}
          customStyles={customStyles}
          progressPending={loading}
          sortFunction={customSort}
          defaultSortFieldId={5}
          pagination
        />
        }
        </div>; 
} 

const customSort = (rows, selector, direction) => {
	return rows.sort((a, b) => {
		const aField = selector(a);
		const bField = selector(b);

		let comparison = 0;

		if (aField > bField) {
			comparison = 1;
		} else if (aField < bField) {
			comparison = -1;
		}

		return direction === 'asc' ? comparison * -1 : comparison;
	});
};




function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage);
}

function toPages(pages) {
  const results = [];

  for (let i = 1; i < pages+1; i++) {
    results.push(i);
  }

  return results;
}
const popover = (data) =>(  
  
  <Popover id={`popover-positioned-bottom`}>  
          <Popover.Header as="h5"></Popover.Header>  
          <Popover.Body style={{color:'#a2a2a2'}}>  
            {data}  
          </Popover.Body>  
        </Popover> 
); 

const columns = [
  {
    name: "Request Id",
    selector: (row) => {
      let rq=row.requestNumber;
      if(rq=='null' || rq==null){return '';}
      else{return <> <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={popover(row.summary)}
      >
        <span >{row.requestNumber}</span>
      </OverlayTrigger></>}
      },
    sortable: true,
    wrap: true,
  },
  {
    name: "Company Name",
    selector: (row) => row.company,
    sortable: true,
    wrap: true,
    minWidth:"200px",
  },
  {
    name: "Project Name",
    selector: (row) => row.project,
    sortable: true,
    wrap: true,
    minWidth:"200px",
  },
  {
    name: "Request Type",
    selector: (row) => row.type,
    sortable: true,
    wrap: true,
    minWidth:"300px",
  },
  {
    name: "Request Date",
    selector: (row) => row.createdDate,
    format: (row) => {if(row.createdDate!=null) return moment(row.createdDate).format("DD-MM-YYYY hh:mm:ss")},
    sortable: true,
    wrap: true,
    //dataFormat:{dateFormatter}
    
    //right: true,
  },
  {
    name: "Request Status",
    selector: (row) => row.status,
    sortable: true,
    wrap: true,
    //right: true,
    

    //right: true
    conditionalCellStyles: [
        {
            when: row => row.status == 'New',
            style: {
                color: 'blue',
                '&:hover': {
                    cursor: 'pointer',
                },
                textAlign: 'left',
            },
        },
        {
            when: row => row.status == 'Failed' || row.status == 'Closed Incomplete',
            style: {
                color: 'red',
                '&:hover': {
                    cursor: 'pointer',
                },
                textAlign: 'left',
            },
        },
        {
            when: row => row.status == 'Closed Skipped',
            style: {
                color: 'green',
                '&:hover': {
                    cursor: 'pointer',
                },
                textAlign: 'left',
            },
        },
    ],

  }
];

// RDT exposes the following internal pagination properties
const BootyPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage
}) => {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };
//alert('-rowCount--'+rowCount);
  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const pageItems = toPages(pages);
  //alert('-pageItems--'+pageItems);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  return (
    <div>
    {rowCount>=10?
      <nav>
      <ul className="pagination justify-content-end pagination-large m-3 me-0">
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page"
          >
            Previous
          </button>
        </li>
        {pageItems.map((page) => {
          const className =
            page === currentPage ? "page-item active" : "page-item";

          return (
            <li key={page} className={className}>
              <button
                className="page-link"
                onClick={handlePageNumber}
                value={page}
              >
                {page}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
      :<></>}
      </div>
      );
};




export default ViewServiceRequest;