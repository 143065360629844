import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {useState, useEffect} from 'react';
import { Button } from 'react-bootstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './MembersModal.css';
import { useParams} from "react-router-dom";
import { ToastContainer, toast ,Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProjectsService from '../../Services/ProjectsService';
function MembersModal({ModalHeading,setMemberModalOpen}) {
 
  const [showModal, setShow] = useState({setMemberModalOpen}); 
  const handleClose = () => setShow(false); 
  const handleShow = () => setShow(true);
  const [data, setData] = useState({});
  const [email, setEmail] = useState('');
  const {companyId,projectId} = useParams();
  const [clickedOnAddMemberButton, setClickedOnAddMemberButton] = useState(false);
  var currentProjectId = {projectId};

  const handleSubmit = async () => {
    //alert("inside handle submit email/projectid"+email,projectId)

    try {

      const response = await ProjectsService.addNewProjectMemberByProjectId(projectId,email);
      if(response)
      {
        console.log(response.message)
        toast.success("Member added Successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 8000, //6 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          transition: Slide
        });
        setMemberModalOpen(false);
        setTimeout(() => { 
          window.location.reload();
      }, 4000);
      }

      console.log(response.data);

    } catch (error) {

      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 8000, //6 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transition: Slide
      });

    }

  }
  return(
   
    <div className="modalBackground" style={{opacity:'1'}}>
   
    {/* corrected modal for adding member */}
    <Modal
      //{...props}
      animation={false}
      show={showModal}
      onHide={handleClose}
       size='lg'
      aria-labelledby="contained-modal-title-vcenter" 
      centered
      backdrop='static' 
       keyboard="False"
       
       
       
    >
      <Modal.Header className='apim-pop-header'>
        <Modal.Title id="contained-modal-title-vcenter">
         Add New Member
        </Modal.Title>
        {/* <div class="rightSec-head"> */}
        <a href="javascript:;" class="pop-close-wrap mpa-button-cancel" onClick={() => {
               setMemberModalOpen(false);
             }}>
        <span class="bi bi-x-lg"></span></a>
           
        {/* </div> */}
      </Modal.Header>
      <Modal.Body>
      <div className="custom-input-wrapp has-validation">
      <FloatingLabel
        controlId="memberNameInput"
        label="Add New Member"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setEmail(event.target.value)}
      >
        <Form.Control type="projectMemberName" placeholder="Eg: username@domain.com" />
         {/* <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Email Id is invalid</span> */}
      </FloatingLabel>
      </div>
          
         
      </Modal.Body>
      <Modal.Footer>
      <button className="ui button comm-button-style primary apim-pop-button-create" id="addNewMemberButton"
        onClick={() => {
          handleSubmit();
          setClickedOnAddMemberButton(true);
        }} disabled={email.length===0} > 
        {setClickedOnAddMemberButton===true?"Creating..." :"ADD NEW MEMBER"}
        </button>
        <button className="ui button comm-button-style secondary apim-pop-button-cancel" id="closeButton"
       onClick={() => {
        setShow(false);
      }}  disabled={email.length===0} > 
        Cancel
        </button>
  
        <div className="toast-container"><ToastContainer limit={2}/></div>
      </Modal.Footer>
    </Modal>
    <div className="toast-container"><ToastContainer limit={2}/></div>
    </div>
  );
  
      
}
export default MembersModal;


